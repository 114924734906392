import React from 'react';
import { getI18n } from "react-i18next";


type DropdownProps = {
    id: string;
    value: string;
    onChange: (item: string) => void;
    schema: {
        enum: string[];
    }
};

export default function DropdownWidget(props: DropdownProps) {
    if (!props.value && !props.schema.enum.includes("")) {
        props.schema.enum = ["", ...props.schema.enum]
    }

    return (
        <select key={props.id} onChange={e => props.onChange(e.target.value)} value={props.value || ""} id={props.id} className="form-control">
            {props.schema.enum && props.schema.enum.map((item: string) => <option key={item} value={item}>{getI18n().t(item)}</option>)}
        </select>
    )
}
