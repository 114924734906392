import Oidc from "oidc-client";

const getOidcConfig = () => {
  const localhostFallback = "oidc.sandbox.ztlpay.io";
  const LOCALHOST = "localhost";
  const getAuthority = host => {
    if (host.indexOf(LOCALHOST) !== -1) {
      return localhostFallback;
    }

    return `${host
      .split(".")
      .map((el, i) => (i === 0 ? "oidc" : el))
      .join(".")}`;
  };

  const { protocol = "https:", host = "demo.sandbox.ztlpay.io" } = window.location;
  const baseUrl = `${protocol}//${host}`;

  return {
    authority: `https://${getAuthority(host)}`,
    client_id: "onboarding",
    redirect_uri: `${baseUrl}/callback`,
    post_logout_redirect_uri: `${baseUrl}/callback-logout`,
    response_type: "code",
    scope: "openid profile national_id storage",
    ui_locales: "nb",
  };
}

function getUser() {
  const config = JSON.parse(sessionStorage.getItem("stacc-oidc"));
  if (!config) {
    return new Promise(resolve => {
      resolve(null);
    });
  }
  const mgr = new Oidc.UserManager(config);
  return mgr.getUser();
}

function signinRedirectCallback() {
  const config = JSON.parse(sessionStorage.getItem("stacc-oidc"));
  if (!config) {
    return new Promise((resolve, reject) => {
      reject("No oidc config");
    });
  }
  const mgr = new Oidc.UserManager(config);
  return mgr.signinRedirectCallback();
}

function signoutRedirect() {
  const config = JSON.parse(sessionStorage.getItem("stacc-oidc"));
  if (!config) {
    return new Promise((resolve, reject) => {
      reject("No oidc config");
    });
  }
  const mgr = new Oidc.UserManager(config);
  return mgr.signoutRedirect();
}

export { getUser, signinRedirectCallback, signoutRedirect, getOidcConfig };
