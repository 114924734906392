import React from "react";
import { Text } from "../../../styles/fonts";
import IconCheck from "../../../icons/Check";
import styled from "styled-components";
import {PINK} from "../../../styles/colors";

type CheckBoxProps = {
    value: boolean | null;
    onChange: (c: boolean) => void;
    schema: {
        checkText: string;
        altDescription?: string;
    };
    rawErrors?: Array<string>;
};

export default function CheckBox(props: CheckBoxProps) {
    const hasErrors = props.rawErrors && props.rawErrors.length > 0;


if(props.schema.altDescription){
    return (
        <>
            <Text style={{ marginBottom: 20, marginTop:50 }}>{props.schema.altDescription}</Text>
        <Frame>
            <CheckFrame
                onClick={(e) => {
                    e.preventDefault();
                    props.value ? props.onChange(false) : props.onChange(true);
                }}
            >
                {props.value ? <IconCheck /> : <div />}
            </CheckFrame>
            <Text style={{ color: hasErrors ? "red" : undefined }}>{props.schema.checkText}</Text>
        </Frame>
        </>
    );
}
else{
    return (
        <>
        <Frame>
            <CheckFrame
                onClick={(e) => {
                    e.preventDefault();
                    props.value ? props.onChange(false) : props.onChange(true);
                }}
            >
                {props.value ? <IconCheck /> : <div />}
            </CheckFrame>
            <Text style={{ color: hasErrors ? "red" : undefined }}>{props.schema.checkText}</Text>
        </Frame>
        </>
    );
}
}

const CheckFrame = styled.button`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: none;
    border-radius: 6px;
    border: 3.58px solid ${PINK};
    padding: 0;
    margin: 0;
    height: 20px;
    min-width: 20px;
    max-width: 20px;
    margin-right: 10px;
`;

const Frame = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
`;