import React from "react";

export default class ProgressDone extends React.Component {
  render() {
    var opacity = this.props.opacity;
    const height = this.props.big ? "140" : "27";
    const width = this.props.big ? "130" : "24";
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
        <g fill="none">
          <circle cx="12" cy="12" r="8" fill="#66CD73" fillOpacity={opacity} />
          <path
            fill="#FFF"
            fillOpacity={opacity}
            fillRule="nonzero"
            d="M11 13.586l4.793-4.793 1.414 1.414-6.207 6.207-3.707-3.707 1.414-1.414z"
          />
        </g>
      </svg>
    );
  }
}
