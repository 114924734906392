import React from "react";
import {PINK} from "../styles/colors";

type SelectProps = {
  color?: string;
};

export default class Roadblock extends React.Component<SelectProps> {
  render() {
    return (
      <svg
        width="102"
        height="102"
        viewBox="0 0 142 142"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="71" cy="71" r="67" stroke="#29323F" strokeWidth="8" />
        <path
          d="M75.1 82.2H66.7V31.2H75.1V82.2ZM70.9 90.6C72.5667 90.6 73.9667 91.2 75.1 92.4C76.3 93.5333 76.9 94.9333 76.9 96.6C76.9 98.2667 76.2667 99.7 75 100.9C73.8 102.033 72.4333 102.6 70.9 102.6C69.3667 102.6 67.9667 102.033 66.7 100.9C65.5 99.7 64.9 98.2667 64.9 96.6C64.9 94.9333 65.4667 93.5333 66.6 92.4C67.8 91.2 69.2333 90.6 70.9 90.6Z"
          fill={PINK}
        />
      </svg>
    );
  }
}