//Framework
import React, { useState, useEffect } from "react";
import Form from "react-jsonschema-form";
import styled from "styled-components";
import lodashMerge from "lodash.merge";

//Styles and icons
import { Button as BaseButton } from "../../../styles/base";
import { TitleSmall } from "../../../styles/fonts";
import Spinner from "../../../icons/InboxSpinner";

//Form components and config
import {
  mapInititialDataToFormData,
  customWidgets,
  generateBaseUISchema,
} from "../form-modifiers";
import ZtlCustomCountryPicker from "../form-components/ZtlCustomCountryPicker";
import ZtlCustomMoneyOrigin from "../form-components/ZtlCustomMoneyOrigin";
import CheckBox from "../form-components/CheckBox";
import ArrayFieldTemplate from "../form-modifiers/ArrayFieldTemplate";
import ObjectFieldTemplate from "../form-modifiers/ObjectFieldTemplate";
import FieldTemplate from "../form-modifiers/CustomFieldTemplate";

function checkIfTypeIsCorrect(data, section, sectionKey) {
  const type =
    section[sectionKey].type === "array" ? "object" : section[sectionKey].type;
  return typeof data[sectionKey] === type;
}

function checkIfSectionIsInvalid(data, section) {
  if (!section) return true;
  return Object.keys(section).reduce(
    (acc, key) =>
      acc || !data[key] || !checkIfTypeIsCorrect(data, section, key),
    false
  );
}

function reorderSchemaInvalidSectionsLast(formData, schemaOld) {
  const newSchema = JSON.parse(JSON.stringify(schemaOld));
  Object.keys(schemaOld.properties).forEach((section) => {
    if (
      checkIfSectionIsInvalid(
        formData,
        schemaOld.properties[section].properties
      )
    ) {
      const obj = newSchema.properties[section];
      delete newSchema.properties[section];
      newSchema.properties[section] = obj;
    }
  });

  return newSchema;
}

function handleSubmit(
  data,
  event,
  onSubmit,
  formData,
  setIsError,
  setErrorMessage
) {
  event.preventDefault();
  if (data.errors && data.errors.length) return;

  if (formData["section-board-and-benificial-owners"].stakeholders) {
    let sum = 0;
    let notValidBirthDate = false;
    formData["section-board-and-benificial-owners"].stakeholders.forEach(
      (item) => {
        if (!item || !item.ultimateOwnership) return;

        let percentage = item.ultimateOwnership
          .replace(/\s+/g, "")
          .replace("%", "");

        sum += Number(percentage);

        if (item.birthDate) {
          const birthDate = new Date(item.birthDate);
          const today = new Date();
          if (birthDate > today) notValidBirthDate = true;
        }

        //if ultimateOwnership does not include a percentage sign, add it to the end
        if (!item.ultimateOwnership.includes("%"))
          item.ultimateOwnership += "%";
      }
    );

    //the sum of the percentages cannot be more than 100
    if (sum > 100) {
      setIsError(true);
      setErrorMessage("The total percentage of ownership cannot exceed 100%.");
      return;
    }

    //check if the birthdate is before todays date
    if (notValidBirthDate) {
      setIsError(true);
      setErrorMessage("The birthdate cannot be in the future.");
      return;
    }
  }

  onSubmit(data);
}

function addPropToSubschemaAtIndex(propName, propValue, subschema, i) {
  const newSubschema = { ...subschema };

  newSubschema.properties = {};
  Object.keys(subschema.properties).forEach((key, index) => {
    if (i === index) {
      newSubschema.properties[propName] = propValue;
    }
    newSubschema.properties[key] = subschema.properties[key];
  });
  newSubschema.required.push(propName);

  return newSubschema;
}

const OnboardingForm = (props) => {
  const [formData, setFormData] = useState({});
  const [schema, setSchema] = useState(null);
  const [uiSchema, setUISchema] = useState(null);
  const [isError, setIsError] = useState(false);
  const [useLiveValidation, setUseLiveValidation] = useState(false);
  const [errorMessage, setErrorMessage] = useState(
    "There are required fields that need additional attention. Please look through the form and complete the missing inputs, highlighted in red."
  );

  const onError = (errors) => {
    setIsError(true);
    setUseLiveValidation(true);
  };

  const onChange = (data) => {
    if (data.errors.length === 0) setIsError(false);
    if (data.errors.length > 0) setIsError(true);
    setFormData(data.formData);
  };

  useEffect(() => {
    const companyTypeIsEnk = props.formData.orgType === "ENK";

    if (props.formData)
      setFormData(
        mapInititialDataToFormData(props.formData, props.schema) || {}
      );
    const newSchema = reorderSchemaInvalidSectionsLast(
      props.formData,
      lodashMerge(props.schema, props.uiSchema)
    );
    newSchema["properties"]["section-quick-questions"]["properties"][
      "blackListedCountries"
    ]["type"] = "object";
    newSchema["properties"]["section-quick-questions"]["properties"]["PEP"][
      "tooltip"
    ] = "pep-tooltip";
    newSchema["properties"]["section-board-and-benificial-owners"][
      "summaryTooltip"
    ] = "benificial-owners-tooltip";
    newSchema["properties"]["section-board-and-benificial-owners"][
      "properties"
    ]["stakeholders"]["tooltip"] = "benificial-owners-tooltip";
    if (companyTypeIsEnk) {
      const hasCorporateAccount = {
        type: "boolean",
        altDescription: "corporate-account-description",
        enum: [true],
        checkText: "corporate-account-check-text",
      };
      newSchema["properties"]["section-confirm-contact"] =
        addPropToSubschemaAtIndex(
          "hasCorporateAccount",
          hasCorporateAccount,
          newSchema.properties["section-confirm-contact"],
          0
        );
    }
    setSchema(newSchema);

    const uiSchemaNew = generateBaseUISchema(props.schema);
    uiSchemaNew["section-confirm-contact"]["confirmBeneficial"] = {
      "ui:widget": CheckBox,
    };
    uiSchemaNew["section-confirm-contact"]["confirmDetails"] = {
      "ui:widget": CheckBox,
    };
    uiSchemaNew["section-quick-questions"]["purpose"] = {
      "ui:widget": CheckBox,
    };
    uiSchemaNew["section-quick-questions"]["whereFundsComeFrom"] = {
      "ui:field": ZtlCustomMoneyOrigin,
    };
    uiSchemaNew["section-quick-questions"]["blackListedCountries"] = {
      "ui:field": ZtlCustomCountryPicker,
    };
    if (companyTypeIsEnk) {
      uiSchemaNew["section-confirm-contact"]["hasCorporateAccount"] = {
        "ui:widget": CheckBox,
      };
    }
    setUISchema(uiSchemaNew);
  }, []);

  if (!schema)
    return (
      <div style={{ marginTop: 50 }}>
        <Spinner />
      </div>
    );

  return (
    <StyledForm
      formData={formData}
      FieldTemplate={FieldTemplate}
      ArrayFieldTemplate={ArrayFieldTemplate}
      ObjectFieldTemplate={ObjectFieldTemplate}
      liveValidate={useLiveValidation}
      noHtml5Validate={true}
      uiSchema={uiSchema}
      widgets={customWidgets}
      showErrorList={false}
      schema={schema}
      onSubmit={(data, event) =>
        handleSubmit(
          data,
          event,
          props.onSubmit,
          formData,
          setIsError,
          setErrorMessage
        )
      }
      key={1}
      onError={(error) => onError(error)}
      onChange={(data) => onChange(data)}
    >
      <SubmitbuttonFrame>
        {isError ? <TitleSmall color={"red"}>{errorMessage}</TitleSmall> : null}
        <BaseButton type="submit">
          <TitleSmall style={{ color: "white" }}>Finish up</TitleSmall>
        </BaseButton>
      </SubmitbuttonFrame>
    </StyledForm>
  );
};

const SubmitbuttonFrame = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const StyledForm = styled(Form)`
  text-align: left;
  margin-top: 0px;
  @media (max-width: 700px) {
    width: 100%;
  }

  fieldset {
    border: none;
  }

  .field-object {
    border: none;
  }

  .field-object > p {
    display: none;
  }
  //.expanded > .property-wrapper:nth-child(4) .field-string:first-of-type {
  //  margin-top: 60px;
  //}
  //.expanded > .property-wrapper:nth-child(5) .field-string:first-of-type {
  //  margin-top: 0px;
  //}

  p {
    margin: 0;
  }

  .form-control {
    width: ${(props) => props.width || "60%"};
    margin-right: 20px;
    height: 38px;
    background-color: #ffffff;
    /* Greyscale/G2 */
    border: 1px solid #b8b8b8;
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
    margin-bottom: 30px;
  }
`;

export default OnboardingForm;
