import React, { Component } from "react"; 
import Oidc from "oidc-client";

export default class CallbackLogout extends Component {
  componentDidMount() {
    const config = JSON.parse(sessionStorage.getItem("stacc-oidc"));
    if (!config) {
      return new Promise((resolve, reject) => {
        reject("No oidc config");
      });
    }
    const mgr = new Oidc.UserManager(config);
    return mgr.signoutRedirectCallback().then(() => { 
      window.location.assign(`/onboarding/${localStorage.getItem('flowID')}`);
    })
    .catch(error => {
      console.error("Error in signinRedirectCallback: ", error);
    });
  }
  render() {
    return <div />;
  }
}
