import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Title, TitleBig } from "../../../styles/fonts";
import SummarySection from "../form-components/SummarySection";
import { ObjectFieldTemplateProps } from "react-jsonschema-form";

function isNumber(input: any) {
  return !isNaN(input);
}

const ObjectFieldTemplate = (props: ObjectFieldTemplateProps) => {
  const [isOpen, setIsOpen] = useState(true);
  const [summarized, setSummarized] = useState(false);
  const [isArrayElem] = useState(
    isNumber(props.idSchema.$id.split("_").slice(-1)[0])
  );
  let properties = props.properties;
  useEffect(() => {
    let isValidForm = Object.keys(props.formData).reduce(
      (acc, next) =>
        props.formData[next] || props.formData[next] === false ? acc : false,
      true
    );
    // If stakeholder is missing birthdate or ultimate ownership is not a number, we want to show the edit version of the form instead.
    // Mainly to make it more visible that it has to be filled out before you can submit the form.
    if (props.formData.stakeholders && props.formData.stakeholders.length > 0) {
      isValidForm = !props.formData.stakeholders.some(
        (person: any) =>
          !person.birthDate ||
          person.ultimateOwnership.toLowerCase().includes("nan")
      );
    }
    setSummarized(isValidForm);
  }, []);
  if (props.formData.preFilled) {
    properties = properties.map((element: any) => {
      if (
        element.content.key === "firstName" ||
        element.content.key === "lastName" ||
        element.content.key === "birthDate"
      ) {
        return {
          ...element,
          content: {
            ...element.content,
            props: {
              ...element.content.props,
              readonly: true,
            },
          },
        };
      } else {
        return element;
      }
    });
  }
  let className = isOpen ? "expanded" : "collapsed";
  if (isArrayElem) className += " arr-elem";
  if (summarized && props.title)
    return (
      <SummarySection
        changeEditState={() => setSummarized(false)}
        title={props.title}
        data={props.formData}
        schema={JSON.parse(JSON.stringify(props.schema))}
      />
    );
  return (
    <div key={props.idSchema.$id}>
      {props.title && props.title !== "none" ? (
        <FormSectionTitleFrame>
          <TitleBig
            style={{
              textAlign: "center",
              marginTop: 60,
              marginRight: 20,
            }}
          >
            {props.title}
          </TitleBig>
          {props.title && !props.formData.visible ? (
            <div
              style={{
                marginRight: 10,
                marginTop: 3,
                position: "absolute",
                right: 10,
              }}
            ></div>
          ) : (
            ""
          )}
        </FormSectionTitleFrame>
      ) : (
        ""
      )}
      {!isArrayElem ? <Title>{props.description}</Title> : ""}
      <ObjectFieldFrame className={className}>
        {properties.map((element, index) => (
          <div
            style={{ marginTop: isArrayElem ? -30 : undefined }}
            key={index}
            className="property-wrapper"
          >
            {element.content}
          </div>
        ))}
      </ObjectFieldFrame>
    </div>
  );
};

const FormSectionTitleFrame = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  position: relative;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;

const ObjectFieldFrame = styled.div`
  transition: 1s;

  & .arr-elem {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 5px;
    p {
      font-size: 14px;
      font-weight: 300;
    }
    border: 1px solid #e6e6e6;
    @media (max-width: 960px) {
      display: flex;
      flex-direction: column;
      max-width: 100%;
    }
    div:has(input) {
      margin-top: 10px;
      margin-left: 0 !important;
    }
  }

  & .collapsed {
    transition: 0.8s;
    opacity: 0;
    max-height: 0px;
    overflow: hidden;
  }

  & .expanded {
    transition: 0.2s;
    opacity: 1;
    max-height: 5000px;
    overflow: default;
  }
`;

export default ObjectFieldTemplate;
