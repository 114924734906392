import React, { useState } from "react";
import styled from "styled-components";
import CheckBox from "./CheckBox";
import { Text } from "../../../styles/fonts";
import { LIGHTGRAY, SNOW } from "../../../styles/colors";

type MoneyOriginProps = {
  onChange: (d: FormData) => void;
  formData: FormData;
  rawErrors?: Array<string>;
};

type FormData = Array<MoneyOriginField>;

type MoneyOriginField = {
  field: string;
  value: string;
};

enum MoneyOriginFields {
  Profits = "profits-from-operating-business",
  Shareholders = "shareholder-contribution",
  LoanFinancialInstitution = "loan-from-fin-inst",
  LoanOther = "loan-other-than-fin-inst",
  Other = "other",
}

const initialForm: FormData = [
  { field: MoneyOriginFields.Profits, value: "false" },
  { field: MoneyOriginFields.Shareholders, value: "false" },
  { field: MoneyOriginFields.LoanOther, value: "false" },
  { field: MoneyOriginFields.LoanFinancialInstitution, value: "false" },
  { field: MoneyOriginFields.Other, value: "" },
];

function handleCheckboxClick(
  checked: boolean,
  checkboxText: string,
  formData: FormData,
  onChange: (d: FormData) => void
) {
  const newFormData: FormData = formData.filter(
    (el) => el.field !== checkboxText
  );
  newFormData.push({ field: checkboxText, value: JSON.stringify(checked) });
  onChange(newFormData);
}

function handleOtherInput(
  input: string,
  formData: FormData,
  onChange: (d: FormData) => void
) {
  const newFormData: FormData = formData.filter(
    (el) => el.field !== MoneyOriginFields.Other
  );
  newFormData.push({ field: MoneyOriginFields.Other, value: input || "" });
  onChange(newFormData);
}

export default function ZtlCustomMoneyOrigin(props: MoneyOriginProps) {
  const [checkVal1, setCheckVal1] = useState(false);
  const [checkVal2, setCheckVal2] = useState(false);
  const [checkVal3, setCheckVal3] = useState(false);
  const [checkVal4, setCheckVal4] = useState(false);
  const [checkVal5, setCheckVal5] = useState(false);
  const [other, setOther] = useState("");

  const formData = props.formData || initialForm;

  const isValid = other.length > 0;

  return (
    <MoneyOriginWrapper>
      <Frame>
        <CheckBox
          schema={{ checkText: "Overskudd fra driften av virksomheten" }}
          onChange={(val: boolean) => {
            setCheckVal1(val);
            handleCheckboxClick(
              val,
              MoneyOriginFields.Profits,
              formData,
              props.onChange
            );
          }}
          value={checkVal1}
        />
        <CheckBox
          schema={{ checkText: "Aksjonærinnskudd" }}
          onChange={(val: boolean) => {
            setCheckVal2(val);
            handleCheckboxClick(
              val,
              MoneyOriginFields.Shareholders,
              formData,
              props.onChange
            );
          }}
          value={checkVal2}
        />
        <CheckBox
          schema={{ checkText: "Lån fra finansforetak" }}
          onChange={(val: boolean) => {
            setCheckVal3(val);
            handleCheckboxClick(
              val,
              MoneyOriginFields.LoanFinancialInstitution,
              formData,
              props.onChange
            );
          }}
          value={checkVal3}
        />
        <CheckBox
          schema={{ checkText: "Lån fra andre enn finansforetak" }}
          onChange={(val: boolean) => {
            setCheckVal4(val);
            handleCheckboxClick(
              val,
              MoneyOriginFields.LoanOther,
              formData,
              props.onChange
            );
          }}
          value={checkVal4}
        />
        <CheckBox
          schema={{ checkText: "Annet" }}
          onChange={(val: boolean) => {
            setCheckVal5(val);
          }}
          value={checkVal5}
        />
      </Frame>
      {checkVal5 && (
        <>
          <Text style={{ marginLeft: 20, marginTop: 10 }}>
            Vennligst Spesifiser
          </Text>
          <SuggestionInputFrame
            className={isValid ? "valid" : "invalid"}
            isValid={isValid}
          >
            <Input
              onChange={(e) => {
                setOther(e.target.value);
                handleOtherInput(e.target.value, formData, props.onChange);
              }}
              value={other}
              isValid={isValid}
            />
          </SuggestionInputFrame>
        </>
      )}
    </MoneyOriginWrapper>
  );
}

const Frame = styled.div``;

const MoneyOriginWrapper = styled.div`
  margin-bottom: 20px;
`;

const Input = styled.input<SuggestionInputFrameProps>`
  width: ${(props) => props.width || "100%"};
  border: none;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
  outline: none;
  height: 100%;
  background-color: ${(props) => (props.isValid ? LIGHTGRAY : "#FFFF")};

  @media (max-width: 700px) {
    font-size: 12px;
  }
`;

type SuggestionInputFrameProps = {
  width?: string;
  isValid: boolean;
};

const SuggestionInputFrame = styled.div<SuggestionInputFrameProps>`
  margin-left: 20px;
  width: ${(props) => props.width || "60%"};
  margin-right: 20px;
  margin-bottom: 20px;
  height: 40px;

  box-sizing: border-box;
  display: -ms-grid;
  -ms-grid-columns: 1fr 25px;
  display: grid;
  grid-template-columns: 1fr 25px;
  margin-top: 0px;
  overflow: hidden;

  align-items: center;
  cursor: pointer;

  position: relative;

  &.invalid {
    border: 1px solid #b8b8b8;
    background-color: ${SNOW};
  }

  &.valid {
    border: 1px solid #b8b8b8;
    background-color: ${LIGHTGRAY};
  }

  .grid-elem:nth-child(2) {
    -ms-grid-column: 2;
  }
`;
