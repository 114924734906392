//Framework
import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";

//Custom components
import OnboardingForm from "../../../form/form-types/section-form";
import uiSchema from "../../../form/form-types/uiSchema.json";
import Spinner from "../../../../icons/InboxSpinner";

//Actions
import { updateFlow } from "../../../../store/flow/actions";
import { throwError } from "../../../../store/error/actions";

//Types
import { FlowState, Schema } from "../../../..//store/flow/types";
import { Status } from "../../../../store/onboarding-status/types";
import { ApplicationState } from "../../../../store";
import { FormSubmit } from "react-jsonschema-form";
import {Text, TitleBig} from "../../../../styles/fonts";
import {PINK} from "../../../../styles/colors";

type Props = {
  throwError: (error: string) => void;
  updateFlow: (data: FlowState) => void;
  onSubmit: (data: FlowState) => void;
  currentActive: Status | undefined;
  flow: FlowState;
};

type State = {
  formData?: object | undefined;
  schema?: Schema | undefined;
};

class OnboardCompany extends Component<Props, State> {
  state = {
    formData: undefined,
    schema: undefined
  };

  componentDidMount = async () => {
    const { data: schema } = await axios.get(`/api/onboarding/schema/complete-kyc-schema`);
    this.setState({ schema });
  };

  render() {
    const currentActive = this.props.currentActive || { name: "No active", state: "not-found" };

    if (!this.state.schema)
      return (
        <div style={{ marginTop: 50 }}>
          <Spinner />
        </div>
      );
    switch (currentActive.name) {
      case "Register":
        return [
          <TitleBig style={{ marginTop: 50 }} color={PINK}>Inngåelse av kundeforhold med ZTL Payment Solution</TitleBig>,
          <Text style={{ marginTop: 20, marginBottom: 20 }} >Du er nå i ferd med å registrere deg som betalingskunde hos vår partner, ZTL. I henhold til hvitvaskingsloven er ZTL Payment Solution AS pliktig til å innhente og få bekreftet opplysninger fra sine kunder.</Text>,
          <OnboardingForm
            formData={this.generateInitialForm()}
            uiSchema={uiSchema}
            onSubmit={(data: FormSubmit<any>) => this.props.onSubmit(data.formData)}
            schema={this.state.schema}
          />
        ];
      default:
        return <div>Could not identify current onboarding state</div>;
    }
  }

  generateInitialForm() {
    if (!this.props.flow.tasks) return {};
    const { context } = this.props.flow.tasks[0];
    return context.company;
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    currentActive: state.onboardingStatus.statusLines.find(status => status.state === "active"),
    flow: state.flow
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateFlow: (flow: FlowState) => dispatch(updateFlow(flow)),
    throwError: (error: string) => dispatch(throwError(error))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OnboardCompany);
