import React from "react";
import axios from "axios";
import styled from "styled-components";
import { Text, Title } from "../../../styles/fonts";
import { PINK } from "../../../styles/colors";

type PostalCodeProps = {
  formData: { postalNumber: string; postalPlace: string };
  onChange: ({ postalNumber, postalPlace }: { postalNumber: string; postalPlace: string }) => void;
  onPostalPlaceChange: (postalPlace: string) => void;
  schema: { hints: string[]; size: string; minLength?: number; length?: number };
  width?: string;
  id: string;
};

type PostalCodeState = {
  postalPlace: string;
};

class PostalCode extends React.Component<PostalCodeProps, PostalCodeState> {
  state = { postalPlace: "" };

  render() {
    const isValid = this.isValidInputField();
    return (
      <div>
        <Title style={{ marginLeft: 30, marginBottom: 5 }}>Postal code</Title>
        <PostalCodeFrame>
          <InputFrame isValid={isValid}>
            <Input
              list={`${this.props.id}-hints`}
              name={`${this.props.id}-hints`}
              onChange={this.handleInputChange}
              isValid={isValid}
            />
          </InputFrame>
          <Text style={{ marginLeft: 10, fontSize: 16, marginTop: -16 }}>{this.state.postalPlace}</Text>
        </PostalCodeFrame>
      </div>
    );
  }

  isValidInputField() {
    if (!this.props.formData) return false;
    const postalNumberCondition = this.props.formData.postalNumber !== "" && this.props.formData.postalNumber !== undefined;
    const postalPlaceCondition = this.props.formData.postalPlace !== "" && this.props.formData.postalPlace !== undefined;

    return postalNumberCondition && postalPlaceCondition;
  }

  handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.fetchPostnumber(e.target.value);
  };

  fetchPostnumber = async (pnr: string) => {
    if (!pnr || pnr.length !== 4) return;
    const { data } = await axios.get(
      `https://api.bring.com/shippingguide/api/postalCode.json?clientUrl=http://localhost:3000&pnr=${pnr}&country=NO`
    );
    this.setState({ postalPlace: data.result });
    this.props.onChange({ postalNumber: pnr, postalPlace: data.result });
  };

  componentDidMount() {
    this.fetchPostnumber(this.props.formData && this.props.formData.postalNumber);
  }
}

const PostalCodeFrame = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 5px;
`;

const Input = styled.input<InputFrameProps>`
  background-color: ${props => (props.isValid ? "#F9F9F9" : "#ffffff")};
  width: 110%;
  border-radius: 4px;
  border: none;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
  outline: none;
  height: 100%;

  @media (max-width: 700px) {
    font-size: 12px;
  }
`;

type InputFrameProps = {
  isValid: boolean;
};

const InputFrame = styled.div<InputFrameProps>`
  /* autoprefixer grid: autoplace */
  width: 80px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  height: 40px;
  background-color: #ffffff;
  /* Greyscale/G2 */
  border: 1px solid #E2E2E2;
  &:focus-within {
    border: ${props => (props.isValid ? "1px solid #E2E2E2" : `1px solid #E2E2E2`)};
  }
  box-sizing: border-box;
  border-radius: 4px;
  display: -ms-grid;
  -ms-grid-columns: 1fr 25px;
  display: grid;
  grid-template-columns: 1fr 25px;
  margin-top: 0px;
  overflow: hidden;

  .grid-elem:nth-child(2) {
    -ms-grid-column: 2;
  }
  align-items: center;
  cursor: pointer;

  position: relative;
`;

export default PostalCode;
