import React, { useState } from "react";
import styled from "styled-components";
import { LIGHTGRAY } from "../../../styles/colors";

interface DatePickerProps {
    value: string,
    readonly : boolean,
    onChange: (value: string) => void,
}

const DatePicker = (props: DatePickerProps) => {
    const [value, setValue] = useState(props.value);
    return (
        <StyledDatePicker>
            <StyledInput 
                type="date" 
                value={value}
                disabled={props.readonly}
                onChange={event => {
                    setValue(event.target.value);
                    props.onChange(event.target.value);
                }
            }>
            </StyledInput>
        </StyledDatePicker>)
};


const StyledInput = styled.input`
border: none;
background-color: #f9f9f9;
flex: max-content;
display:flex;
padding-left:10px;
padding-right:10px;
font-family: inherit !important;
`

const StyledDatePicker = styled.div`
    display:flex;
    width: 200px;
    margin-left:20px
    height: 40px;
  
    border-radius: 4px;
  
    align-items: center;
    cursor: pointer;
  
    position: relative;
  
    border: 1px solid #b8b8b8;
    background-color: ${LIGHTGRAY};
  `

export default DatePicker;