import React from "react";
import styled from "styled-components";
import BooleanRadioWidget from "./BooleanRadioWidget";
import { Title, Text } from "../../../styles/fonts";
import {PINK} from "../../../styles/colors";

const checkboxEntries = [
    "EU/EØS eller UK",
    "USA",
    "Afghanistan",
    "Albania",
    "Bahamas",
    "Barbados",
    "Bosnia-Hercegovina",
    "Botswana",
    "Etiopia",
    "Ghana",
    "Guyana",
    "Irak",
    "Iran",
    "Island",
    "Jamaica",
    "Jemen",
    "Kambodsja",
    "Laos",
    "Mauritius",
    "Mongolia",
    "Myanmar",
    "Nicaragua",
    "Nord-Korea",
    "Pakistan",
    "Panama",
    "Sri Lanka",
    "Syria",
    "Trinidad og Tobago",
    "Tunisia",
    "Uganda",
    "Vanuatu",
    "Zimbabwe",
    "Andre land"
];

type ZtlCustomProps = {
    onChange: (d: FormData) => void;
    formData: FormData;
    rawErrors?: Array<string>;
};

type FormData = {
    checked: boolean | null;
    countries: Array<TransferToCountry>;
};

type TransferToCountry = {
    country: string,
    willTransfer: string
}

const allCountriesFalse = checkboxEntries.map(el => ({ country: el, willTransfer: "false" })); 

const initialFormData: FormData = {
    checked: null,
    countries: allCountriesFalse
}

function handleCheckboxClick(checked: boolean, cntry: string, formData: FormData, onChange: (d: FormData) => void) {
    const countriesWithoutCurrentCountry: Array<TransferToCountry> = formData.countries.filter(el => el.country !== cntry);
    const newCountries: Array<TransferToCountry> = [...countriesWithoutCurrentCountry, {country: cntry, willTransfer: JSON.stringify(checked)}]
    const newFormData: FormData = { ...formData, countries: newCountries };
    onChange(newFormData);
}

function ZtlCustomCountryPicker(props: ZtlCustomProps) {
    const formData = props.formData || initialFormData;

    return (
        <div>
            <BooleanRadioWidget
                onChange={(v) => props.onChange({ countries: v ? formData.countries : allCountriesFalse, checked: v })}
                value={formData.checked}
            />
            {formData.checked ? (
                <div style={{ marginLeft: 30, marginTop: 5, marginRight: 20 }} >
                    <Title>Hvor?</Title>
                    <MultiSelectFrame>
                        {checkboxEntries.map((cntry) => (
                            <div key={cntry} style={{ display: "flex" }}>
                                <StyledCheckbox
                                    type="checkbox"
                                    onChange={(e) => handleCheckboxClick(e.target.checked, cntry, formData, props.onChange)}
                                />
                                <Text>{cntry}</Text>
                            </div>
                        ))}
                    </MultiSelectFrame>
                </div>
            ) : (
                    ""
                )}
        </div>
    );
}

const MultiSelectFrame = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-left: 50px;
`;

const StyledCheckbox = styled.input`
  appearance: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: none;
  border-radius: 6px;
  border: 3.58px solid ${PINK};
  padding: 0;
  margin: 0;
  height: 20px;
  min-width: 20px;
  max-width: 20px;
  margin-right: 10px;

  &:checked {
    background-color: ${PINK};
  }
`
export default ZtlCustomCountryPicker;
