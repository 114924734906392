import { Reducer } from "redux";
import { ErrorState, ErrorActions } from "./types";

export const initialState: ErrorState = {
  errorData: { name: "", description: "", mainText: "", abortPolling: false },
  display: false
};

const reducer: Reducer<ErrorState> = (state: ErrorState = initialState, action) => {
  switch ((action as ErrorActions).type) {
    case "@@error/UPDATE_ERROR":
      return { ...state, errorData: { ...action.payload }, display: true };
    case "@@error/CLEAR_ERROR":
      return { ...state, display: false };
    default:
      return state;
  }
};

export default reducer;
