import React from "react";
import styled from "styled-components";
import Radio from "../../../icons/Radio";
import { Text } from "../../../styles/fonts";
import { PINK, SNOW } from "../../../styles/colors";

type Props = { value: boolean | null; onChange: (checked: boolean) => void };

const BooleanRadioWidget = function (props: Props) {
  return (
    <RadioFrame>
      <Button
        type="button"
        onClick={() => props.onChange(true)}
        checked={props.value === true}
      >
        <Text style={{ marginLeft: 10 }} color={props.value === true ? SNOW : PINK}>Yes</Text>
      </Button>
      <Button
        type="button"
        onClick={() => props.onChange(false)}
        checked={props.value === false}
      >
        <Text style={{ marginLeft: 10 }} color={props.value === false ? SNOW : PINK}>No</Text>
      </Button>
    </RadioFrame>
  );
};

export default BooleanRadioWidget;

const RadioFrame = styled.div`
  display: flex;
  height: 70px;

`;

const Button = styled.button<{ checked: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 39px;
  width: 60px;
  border: 1.53px solid ${PINK};
  background-color: ${(props) => (props.checked ? PINK : SNOW)};
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;
