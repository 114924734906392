import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./languages/en.json";
import nb from "./languages/nb.json";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";

(i18n.options.react || {}).useSuspense = false;

const detectorOptions = {
  // order and from where user language should be detected
  order: ["querystring", "cookie", "localStorage", "navigator", "htmlTag", "path", "subdomain"],

  // keys or params to lookup language from
  lookupQuerystring: "lng",
  lookupCookie: "i18next",
  lookupLocalStorage: "i18nextLng",
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  // cache user language on
  caches: ["localStorage", "cookie"],
  excludeCacheFor: ["cimode"] // languages to not persist (cookie, localStorage)¨
};

//To add new languages, create a new json object and add key value pairs for your desired language
const resources = {
  en,
  nb
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      detection: detectorOptions,
      resources,
      keySeparator: false,
      nsSeparator: false,
      fallbackLng: "nb",
      react: {
        useSuspense: false
      },
      interpolation: {
        escapeValue: false
      }
    },
    (err, t) => {
      if (err) {
        console.error("error while initialising i18next ::: " + JSON.stringify(err));
      }
    }
  );

export default i18n;
