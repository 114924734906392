import React, { FormEvent } from "react";
import styled from "styled-components";
import Checkbox from "../../../../base/Checkbox";
import { PINK, LIGHTGRAY } from "../../../../styles/colors";
import { Title } from "../../../../styles/fonts";
import { AvailableSigner } from "./types";
import SignerForm from "./SignerForm";

export interface SignerProps {
    onClick: (event: FormEvent) => void;
    fromBisnode: boolean;
    signer: AvailableSigner;
    active: boolean;
    onSignerChange: (signer: AvailableSigner) => void;
}

export interface SignerState {
    signer: AvailableSigner;
}

class Signer extends React.Component<SignerProps, SignerState> {
    state: SignerState = {
        signer: this.props.signer,
    };

    render() {
        return [
            <DataCell style={{ flex: "0" }}>
                <RadioFrame onClick={this.props.onClick}>
                    <Checkbox checked={this.props.active} color={PINK} />
                </RadioFrame>
            </DataCell>,
            <DataCell style={{ flex: 2 }} onClick={this.props.onClick}>
                <Title>
                    {this.state.signer.name === "" ? "Ny signaturberettiget" : this.props.signer.obligatory ? this.props.signer.name + "*" : this.props.signer.name}
                </Title>
            </DataCell>,
            <DataCell onClick={this.props.onClick}>
                <div>
                    {(this.state.signer.roles || []).map((role) => (
                        <Title key={role}>{role}</Title>
                    ))}
                </div>
            </DataCell>,
            <DataRowCell>
                {this.props.active ? (
                        <SignerForm
                            fromBisnode={this.props.fromBisnode}
                            onBlur={this.handleSignerChange}
                            availableSigner={{ ...this.state.signer }}
                        />
                ) : (
                    ""
                )}
            </DataRowCell>
        ];
    }

    handleSignerChange = (signer: AvailableSigner) => {
        this.setState({ signer });
        this.props.onSignerChange(signer);
    };
}

const DataCell = styled.td`
    display: flex;
    align-items: center;
    flex: 1;
`;

const DataRowCell = styled.td`
    display: flex;
    align-items: center;
    width: 100%;
    flex: auto;
`;

const RadioFrame = styled.button`
    cursor: pointer;
    width: 40px;
    height: 66px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border: none;
`;

export default Signer;
