import SuggestionInput from "../form-components/SuggestionInput";
import CustomPhoneInput from "../form-components/CustomPhoneInput";
import Postal from "../form-components/PostalCode";
import DropdownWidget from "../form-components/DropdownWidget";
import BooleanRadioWidget from "../form-components/BooleanRadioWidget";
import { UiSchema } from "react-jsonschema-form";
import DatePicker from "../form-components/DatePicker";
import { JSONSchema6 } from "json-schema";

function handleUISchemaStringType(uiSchema: UiSchema, propertyName: string, property: JSONSchema6) {
  if (property.enum) {
    uiSchema[propertyName] = { "ui:widget": DropdownWidget };
    return;
  }
  if (propertyName.toLowerCase().includes("date")) {
    uiSchema[propertyName] = { "ui:widget": DatePicker };
    return;
  }
  if (propertyName.toLowerCase().includes("phone")) {
    uiSchema[propertyName] = { "ui:widget": CustomPhoneInput }
    return;
  }

  uiSchema[propertyName] = { "ui:widget": SuggestionInput };
}

function handleUISchemaIntegerType(uiSchema: UiSchema, propertyName: string, property: JSONSchema6) {
  uiSchema[propertyName] = { "ui:widget": SuggestionInput };
}
function handleUISchemaObjectType(uiSchema: UiSchema, propertyName: string, property: JSONSchema6) {
  if (propertyName === "postal") {
    uiSchema[propertyName] = { "ui:field": Postal };
    return;
  }

  uiSchema[propertyName] = generateBaseUISchema(property);
}

function handleUISchemaArrayType(uiSchema: UiSchema, propertyName: string, property: JSONSchema6) {
  if (typeof property.items === "object" && !Array.isArray(property.items)) {
    uiSchema[propertyName] = { items: generateBaseUISchema(property.items) };
  }
}

export function generateBaseUISchema(schema: JSONSchema6) {
  const uiSchema: UiSchema = {};
  if (schema.type === "object" && schema.properties) {
    for (const propertyName of Object.keys(schema.properties || {})) {
      const property = schema.properties[propertyName];

      if (typeof property === "object" && !uiSchema[propertyName]) {
        switch (property.type) {
          case "string":
            handleUISchemaStringType(uiSchema, propertyName, property);
            break;
          case "integer":
            handleUISchemaIntegerType(uiSchema, propertyName, property);
            break;
          case "object":
            handleUISchemaObjectType(uiSchema, propertyName, property);
            break;
          case "array":
            handleUISchemaArrayType(uiSchema, propertyName, property);
            break;
        }
      }
    }
  }

  return uiSchema;
}

export function mapInititialDataToFormData(data: { [key: string]: any }, schema: { [key: string]: any }) {
  const formData: { [key: string]: any } = {};
  Object.keys(schema.properties).map(section => {
    formData[section] = {};
    const sectionProperties = schema.properties[section].properties;
    return Object.keys(sectionProperties).map(field => {
      const defaultValue = sectionProperties[field].default;
      const type = sectionProperties[field].type === "array" ? "object" : sectionProperties[field].type;
      formData[section][field] = (typeof data[field] === type ? data[field] : undefined) || defaultValue;
      return field;
    });
  });

  return formData;
}

export function generateBaseUISchemaSection(schema: { [key: string]: any }) {
  return Object.keys(schema.properties).map(section => generateBaseUISchema(schema.properties[section]));
}

export const customWidgets = {
  CheckboxWidget: BooleanRadioWidget
};
