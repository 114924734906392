import { combineReducers, Dispatch, Reducer } from "redux";

import { ContentTitleState } from "./content-title/types";
import { ErrorState } from "./error/types";
import { FlowState } from "./flow/types";
import { OnboardingStatusState } from "./onboarding-status/types";

import contentTitleReducer from "./content-title/reducer";
import errorReducer from "./error/reducer";
import flowReducer from "./flow/reducer";
import onboardingStatusReducer from "./onboarding-status/reducer";

export interface ApplicationState {
  flow: FlowState;
  error: ErrorState;
  contentTitle: ContentTitleState;
  onboardingStatus: OnboardingStatusState;
}

export const reducers: Reducer<ApplicationState> = combineReducers<ApplicationState>({
  contentTitle: contentTitleReducer,
  error: errorReducer,
  flow: flowReducer,
  onboardingStatus: onboardingStatusReducer
});
