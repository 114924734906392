import { ActionCreator } from "redux";
import { ClearErrorAction, ThrowErrorAction } from "./types";
import Errors from "./errors";

// Helper that defaults to generic-error if it is provided. Otherwise return undefined
export function getError(errorType: string, defaultError?: string) {
  if (defaultError) return Errors[errorType] || Errors[defaultError]
   return Errors[errorType] || undefined
}

export const throwError: ActionCreator<ThrowErrorAction> = (error: string, meta: any) => ({
  type: "@@error/UPDATE_ERROR",
  payload: { ...getError(error, "generic-error"), ...(meta ? { ...meta } : null) }
});

export const clearError: ActionCreator<ClearErrorAction> = () => ({
  type: "@@error/CLEAR_ERROR",
  payload: {}
});
