import React from "react";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import { getI18n } from "react-i18next";
import HelpIcon from "../../icons/HelpIcon";
import { PINK } from "../../styles/colors";

type TooltipProps = {
  tooltip: string;
};

export default function Tooltip(props: TooltipProps) {
  return (
    <Frame>
      <a
        style={{ maxWidth: 700, textAlign: "left" }}
        data-tip
        data-for={"global-" + props.tooltip.length}
      >
        <HelpIcon />
      </a>
      <ReactTooltip
        id={"global-" + props.tooltip.length}
        className="tooltip-width"
        place="bottom"
        multiline={true}
        overridePosition={({ left, top }, _e, _t, node) => {
          return {
            top,
            left: typeof node === "string" ? left : Math.max(left, 0),
          };
        }}
        textColor="#1e2f4b"
        backgroundColor="#fbf5f5"
        effect="solid"
        border
        borderColor={PINK}
      >
        <ReactMarkdown source={getI18n().t(props.tooltip)} />
      </ReactTooltip>
    </Frame>
  );
}

const Frame = styled.div`
  .tooltip-width {
    max-width: 500px;
    max-height: 95%;
  }

  text-align: left !important;
`;
