import { AppError, ErrorsInterface } from "./types";
import Globe from "../../icons/Globe";
import React from "react";
import { OnboardingSuccessIcon } from "../../components/onboarding/components/onboarding-icons";

const declineRiskEvaluation: AppError = {
  name: "Error",
  mainText: "Denied application",
  description: "declined-risk-evaluation-description",
  abortPolling: true,
};

const genericError: AppError = {
  name: "",
  mainText: "Beklager, noe gikk galt",
  description: `Prøv å starte på nytt ved å klikke på lenken fra ditt økonomisystem.
  Hvis det fortsatt ikke fungerer, ta kontakt med din økonomisystemleverandør.
  \n Dersom du allerede har registrert din kontaktinfo, vil vi ta kontakt med deg snarest.`,
  abortPolling: false,
};

const errorSigning: AppError = {
  name: "",
  description:
    "We did not get a success message from Signicat - please try again or contact us",
  mainText: "Sign error",
  abortPolling: false,
};

const notFound: AppError = {
  name: "",
  description:
    "We were unable to locate the onboarding on this particular ID - please contact us",
  mainText: "Did not find onboarding",
  abortPolling: true,
};

const manualProcessing: AppError = {
  name: "",
  description: "manual-processing-description",
  mainText: "We have to take a closer look at your application.",
  title: "Application received",
  icon: <Globe />,
  abortPolling: false,
};

const documentsUploaded: AppError = {
  name: "",
  description: "We will be in touch if we have any futher inquiries",
  mainText: "Thanks! We will have a closer look at the uploaded documents",
  title: "Documents uploaded",
  icon: <Globe />,
  abortPolling: false,
};

const noSigners: AppError = {
  name: "",
  description: "Did not receive any signers from the backend",
  mainText: "No signers",
  abortPolling: false,
};

const noRequest: AppError = {
  name: "",
  description: "Could not find any pending file requests on person",
  mainText: "No pending request",
  abortPolling: false,
};

const completed: AppError = {
  name: "",
  description: "ZTLs betalingstjeneste er nå tilgjengelig i økonomisystemet.",
  mainText: "Takk!",
  icon: <OnboardingSuccessIcon />,
  abortPolling: true,
};

const processDeleted: AppError = {
  name: "",
  description:
    "Your application has been canceled, please head to your accounting system to initialize a new application.",
  mainText: "The application is canceled",
  abortPolling: true,
};

const customerExistsError: AppError = {
  name: "",
  mainText: "Already onboarded!",
  description:
    "Your company appears to be onboarded already. If you have any inquiries do not hesitate to contact the provider of your financial system. Have a great day!",
  icon: <OnboardingSuccessIcon />,
  abortPolling: true,
};

const companyNotFound: AppError = {
  name: "",
  mainText: "company-not-found",
  description: "company-not-found-desc",
  abortPolling: true,
};

const voluntaryCreditBlockBisnodeError: AppError = {
  name: "",
  mainText: "voluntary-credit-block-bisnode",
  description: "voluntary-credit-block-bisnode-desc",
  abortPolling: true,
};

const companyNotFoundBisnode: AppError = {
  name: "",
  mainText: "company-not-found-bisnode",
  description: "company-not-found-bisnode-desc",
  abortPolling: true,
};

const SignorderExpired: AppError = {
  name: "",
  mainText: "signorder-expired",
  description: "signorder-expired-desc",
  abortPolling: true,
};

const UnsupportedCompanyType: AppError = {
  name: "Unsupported Company Type",
  mainText: "unsupported-company-type",
  description: "unsupported-company-type-desc",
  abortPolling: true,
  moreInfoText: "org-not-found-more-info-text",
  moreInfoUrl: "org-not-supported-more-info-url",
};

const brregFetchFailed: AppError = {
  name: "Brreg fetch failed",
  mainText: "brreg-fetch-failed",
  description: "brreg-fetch-failed-desc",
  abortPolling: true,
};

const MissingCompanyType: AppError = {
  name: "Missing Company Type",
  mainText: "missing-company-type",
  description: "missing-company-type-desc",
  abortPolling: true,
};

const Errors: ErrorsInterface = {
  "customer-exists-error": customerExistsError,
  "already-onboarded": customerExistsError,
  "declined-risk-evaluation": declineRiskEvaluation,
  "declined-enk-no-vat": genericError,
  "declined-unsupported-company-type": UnsupportedCompanyType,
  "company-not-found-bisnode": companyNotFoundBisnode,
  "generic-error": genericError,
  failed: genericError,
  "failed-fetch-brreg-information": brregFetchFailed,
  "sign-error": errorSigning,
  "not-found-error": notFound,
  "manual-processing": manualProcessing,
  "manual-handling": manualProcessing,
  "no-signers": noSigners,
  "no-request": noRequest,
  "process-deleted": processDeleted,
  archived: processDeleted,
  completed: completed,
  "company-not-found": companyNotFound,
  "signorder-expired": SignorderExpired,
  "documents-uploaded": documentsUploaded,
  "declined-missing-company-type": MissingCompanyType,
  "voluntary-credit-block-bisnode": voluntaryCreditBlockBisnodeError,
};

export default Errors;
