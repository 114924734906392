import React from "react";
import styled from "styled-components";
import { LIGHTGRAY, SNOW, MEDIUMGRAY } from "../../../../styles/colors";
import { Text, Title } from "../../../../styles/fonts";
import ProgressDone from "../../../../icons/ProgressDone";
import { getI18n } from "react-i18next";
import nb from "react-phone-number-input/locale/nb.json"
import en from "react-phone-number-input/locale/en.json"
import PhoneInput from "react-phone-number-input";
import { isPossibleNumber } from "libphonenumber-js";

class SignerForm extends React.Component {
    state = { ...this.props.availableSigner };

    render() {
        const { language } = getI18n()
        const translations = language.toLowerCase() === "nb" ? nb : en
        return (
            <SignerFrame onBlur={() => this.props.onBlur({ ...this.state })}>
                <TextFrame>                     
                <Title style={{ marginLeft: 6, fontSize: 16, marginBottom: 10, marginRight: "auto" }}>{this.state.name}{this.state.marked?"*":""}</Title>
                </TextFrame>
                <TextFrame>
                    <Text style={{ marginLeft: 6 }}>Phone</Text>
                </TextFrame>
                <InputFrame style={{ justifyContent: "start" }}>
                    <div style={{ "width": "90%", "marginRight": "10px" }}>
                        <StyledPhoneInput
                            inputclassname={this.state.mobile.isPossiblePhonenumber ? "valid" : "invalid"}
                            onChange={e => this.onPhoneChange(e || "", "phonenumber")}
                            value={this.state.mobile.phonenumber}
                            defaultCountry="NO"
                            labels={translations}
                        />
                    </div>
                    <div style={{ marginRight: 20, marginBottom: 10 }}>
                        {this.state.mobile.isPossiblePhonenumber || !this.state.marked ? <ProgressDone /> : <div style={{ width: 24 }} />}
                    </div>
                </InputFrame>
            </SignerFrame>
        );
    }
    onPhoneChange(e, key) {
        const state = this.state;
        state.mobile[key] = e;
        state.mobile.isPossiblePhonenumber = isPossibleNumber(e)
        this.setState(state);         
        this.props.onBlur(state);
    }
}


const StyledPhoneInput = styled(PhoneInput)`
    margin-left: 30px;
    width: 60%;

    > input[type=tel] {
      border-radius: 4px;
      padding-left: 10px;
      padding-right: 10px;
      border: 1px solid #b8b8b8;
      outline: initial;
      height: 30px;
      border: ${props => (props.inputClassName !== "valid" ? `1px solid ${MEDIUMGRAY}` : "1px solid transparent")};
      background-color: ${props => (props.inputClassName !== "valid" ? SNOW : "transparent")};
      font-size: 16px;

      :focus {
        background-color: ${SNOW};
        outline: auto blue;
      }
    }
    > * {
      margin-bottom: 10px;
    }
`;

const InputFrame = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const TextFrame = styled.div`
    width: 90%;
    margin-bottom: 5px;
`;

const SignerFrame = styled.div`
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #c9f3ff30;
    border-top: 1px solid ${LIGHTGRAY};
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export default SignerForm;
