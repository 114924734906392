import styled from "styled-components";
import { PINK } from "./colors";

export const Button = styled.button`
  border: none;
  min-height: 63px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 14px;
  line-height: 21px;
  color: ${props => (props.color ? props.color : "#FFFFF")};
  background-color: ${props => (props.bgColor ? props.bgColor : PINK)};
  transition: opacity 0.2s ease-out;

  &:hover {
    opacity: 0.6;
  }
`;
