import Confetti from "react-dom-confetti";
import React, { useState, useEffect } from "react";
import ProgressDone from "../../../icons/ProgressDone";

const confettiConfig = {
  angle: 90,
  spread: 55,
  startVelocity: 25,
  elementCount: 80,
  decay: 0.9
};

export const OnboardingSuccessIcon = () => {
  const [fireConfetti, setFireConfetti] = useState(false);

  useEffect(() => {
    setTimeout(() => setFireConfetti(true), 500);
  });

  return (
    <div>
      <Confetti active={fireConfetti} config={confettiConfig} />
      <ProgressDone big />
    </div>
  );
};
