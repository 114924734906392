//Frameworks
import React, { Component } from "react";
import { Route, Switch, match } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import i18next from 'i18next';

//Custom components
import OnboardCompany from "./components/onboard-company";
import SelectSigners from "./components/select-signers";
import FixSignerDetails from "./components/fix-signer-details";
import SignAgreement from "./components/sign-agreement";

import DetailedLoadingScreen from "./components/detailed-loading-screen";
import Spinner from "../../icons/InboxSpinner";

//Types/interfaces
import { FlowState } from "../../store/flow/types";
import { ApplicationState } from "../../store";
import { Location } from "history";

//Actions 
import { throwError, getError, clearError } from "../../store/error/actions";
import { updateFlow } from "../../store/flow/actions";

//Types
import { Schema } from "../../store/flow/types";


// return id of service-task or flow-status if in arr.
// these are then returned to component and drawn on screen


interface loadingScreenProps {
    tasks: Array<any>;
    status: string;
}




const getStringForDetailedLoadingScreen = (props: loadingScreenProps) => {
    let serviceTask: any = props.tasks.find((task: { category: string; }) => task.category === "service-task")
    serviceTask = !!serviceTask ? serviceTask.type : undefined
    if (serviceTask) return serviceTask

    const singingStatus = ["document-signed-awaiting-packaging", "document-signed-packaging-complete"]
    if (singingStatus.includes(props.status)) return props.status

    return undefined
}
interface OnboardingProps {
    throwError: (error: string, meta?: object) => void;
    updateFlow: (flow: FlowState) => void;
    clearError: () => void;
    match: match<{ flowId: string }>;
    flow: FlowState;
    location: Location;
}

interface OnboardingState {
    schema: Schema | null;
}

class Onboarding extends Component<OnboardingProps, OnboardingState> {
    interval = 0;


    render() {
        if (!this.props.flow.status)
            return (
                <div style={{ marginTop: 50, textAlign:"center" }}>
                    <Spinner />
                </div>
            );
        return (
            <Switch>
                <Route path="/onboarding/:flowId/" render={() => this.getOnboardingPage()} />
                <Route
                    component={() => {
                        this.props.throwError("generic-error", { redirectUrl: this.props.flow.redirectUrl || "", refresh: this.fetchCompanyOnboardingData })
                        return <div />;
                    }}
                />
            </Switch>
        );
    }

    getLanguage() {
        let language = i18next.languages[0] || "en"; //Current selected language
        language = language.toLowerCase()
        language = language.includes("en") ? "en" : "nb"
        return language
    }

    renderPages(task: any) {
        const language = this.getLanguage()
        const { type } = task;
        //TODO:  Fix if traffic becomes an issue.
        // Here we could implement some more intelligent polling. Only poll in components that can actually have their data changed from the backend. Some tasks have static data, meaning tha polling just produces unwanted traffic
        // Previous fix broke entire app by calling function which somewhere in the stack looped back on itself. This produces continuous rerendering
        if (type === "complete-kyc-schema") return <OnboardCompany onSubmit={this.handleCompleteTask} />;
        if (type === "confirm-signees") return <SelectSigners completeTask={this.completeTask} refresh={this.refresh} task={task} language={language} />;
        if (type === "fix-signer-details") return <FixSignerDetails completeTask={this.completeTask} refresh={this.refresh} task={task} language={language} />;
        if (type === "sign-customer-agreement") return <SignAgreement task={task} redirectUrl={this.props.flow.redirectUrl} onComplete={this.handleCompleteTask} location={this.props.location} />;         
    }

    /*
    The mechanism that determines what component to render is a bit messy.
    A task has a type, which correlates to handlerId in flow-process.
    A process has a status(a simple enum that is returned from flow-process and overwritten in api-gw.
        This status can also be flowStatus, which is a unique businessStatus.
    */
    getOnboardingPage() {
        const status = this.props.flow.status;
        const descriptiveLoadingString = getStringForDetailedLoadingScreen({ tasks: this.props.flow.tasks, status })

        // returns loading screen with text description in center. Uses current flow status and list of service-tasks to determine what to render
        if (descriptiveLoadingString) return <DetailedLoadingScreen task={descriptiveLoadingString} />;
        // Return user-task component if there exists a user-task         
        if (this.props.flow.tasks.length > 0 && this.props.flow.tasks.some(task => task.category === "user-task")) {
            const task = this.props.flow.tasks.find(task => task.category === "user-task")
            return this.renderPages(task)
        }
        return [
            <div style={{ marginBottom: 30, marginTop: 20 }} key={0}>
                <div />
            </div>,
            <Spinner key={1} />
        ];
    }

    handleCompleteTask = async (data?: any, task?: any) => {
        try {
            await this.completeTask(0, data, task);
            this.props.updateFlow({ tasks: [], status: "processing", redirectUrl: "" });
            clearInterval(this.interval);
            this.interval = setInterval(() => this.fetchCompanyOnboardingData(), 1000);
        } catch (err) {
            this.props.throwError("generic-error", { redirectUrl: this.props.flow.redirectUrl || "", refresh: this.fetchCompanyOnboardingData });
        }
    };

    refresh = async () => {
        try {
            this.props.updateFlow({ tasks: [], status: "processing", redirectUrl: "" });
            clearInterval(this.interval);
            this.interval = setInterval(() => this.fetchCompanyOnboardingData(), 1000);
        } catch (err) {
            this.props.throwError("generic-error", { redirectUrl: this.props.flow.redirectUrl || "", refresh: this.fetchCompanyOnboardingData })
        }
    }

    componentDidMount() {
        clearInterval(this.interval);
        this.fetchCompanyOnboardingData();
        this.interval = setInterval(() => { this.fetchCompanyOnboardingData(); }, 2000);
    }

    fetchCompanyOnboardingData = async () => {
        const flowId = this.props.match.params.flowId;
        // what if we do not have a specific component nor a error for the given status or task? - must have some default handling
        // loading screen -> "customer-onboarding-completed", create-customer
        // how to handle failed status

        try {
            const { data } = await axios.get(`/api/onboarding/${flowId}/status`, { headers: { "Cache-Control": "no-cache" } });

            const statusIsError = !!getError(data.status)
            if (statusIsError) {
                // is error the same as before? Do not rethrow(redraw)  
                if (data.status !== this.props.flow.status) {
                    // If status is `failed` due to a failed Brreg lookup, show a specific error message
                    const detailedError =
                        data.status === "failed"
                            && data.tasks.some(
                                (task: any) => task.status === "failed" && task.type === "fetch-brreg-information")
                            ? "failed-fetch-brreg-information"
                            : data.status;

                    return this.props.throwError(
                        detailedError,
                        {
                            redirectUrl: data.redirectUrl || "",
                            refresh: this.fetchCompanyOnboardingData,
                        }
                    );
                }
            }
            else {
                // need to clear error before updating state to redraw the user-task component
                await this.props.clearError()
                /*
                To prevent the generic loading spinner from rendering between service-tasks we keep a reference to
                the previous service-task such that this description message does not disappear. 
                */
                const descriptiveLoadingString = getStringForDetailedLoadingScreen({ ...this.props.flow })
                if (!((data.status === "processing" && data.tasks.length === 0) && !!descriptiveLoadingString)) {
                    return this.props.updateFlow(data);
                }

            }
        } catch (error) {
            console.log(error)
            if (error && error.response && error.response.status === 404) {
                clearInterval(this.interval);
                return this.props.throwError("not-found-error", { redirectUrl: this.props.flow.redirectUrl || "", refresh: this.fetchCompanyOnboardingData });
            }
            return this.props.throwError("generic-error", { redirectUrl: this.props.flow.redirectUrl || "", refresh: this.fetchCompanyOnboardingData });
        }
    };

    getFlowIdFromPath() {
        return this.props.location.pathname.split("/")[3];
    }

    completeTask = async (counter = 0, data?: any, task?: any) => {
        const cTask = task ? task : this.props.flow.tasks[0] || {};
        const flowId = cTask.flowId;
        const taskId = cTask.id;
        if (flowId && taskId) {
            try {
                await axios.post(`/api/onboarding/${flowId}/tasks/${taskId}/complete`, data);
            } catch (err) {
                if (err.response && err.response.status === 400) return err.response
                else if (counter <= 3) {
                    await this.completeTask(counter + 1, data, task);
                    return;
                }
                else return this.props.throwError("generic-error", { redirectUrl: this.props.flow.redirectUrl || "", refresh: this.fetchCompanyOnboardingData })                 
            }
        }
    };

    componentWillUnmount() {
        clearInterval(this.interval);
    }
}

const mapStateToProps = (state: ApplicationState) => ({ flow: state.flow });

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateFlow: (flow: FlowState) => dispatch(updateFlow(flow)),
        throwError: (error: string, meta?: object) => dispatch(throwError(error, meta)),
        clearError: () => dispatch(clearError())
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Onboarding);
