import React, { Component } from "react";
import styled from "styled-components";
import { Title, TitleSmall, Text } from "../../../styles/fonts";
import IconPen from "../../../icons/PenSimple";
import Tooltip from "../../tooltip";
import Business from "../../../icons/Business";
import Roles from "../../../icons/Roles";
import Roles2 from "../../../icons/Roles2";

type SummarySectionProps = {
  data: { [index: string]: any };
  title: string;
  changeEditState: (title: string) => void;
  schema: {
    locked?: boolean;
    summaryTooltip: string;
  };
};

export default class SummarySection extends Component<SummarySectionProps> {
  render() {
    return (
      <Frame>
        <IconAndTitleWrapper>
          {this.props.title === "Company information" && <Business />}
          {this.props.title === "Ownership and roles" && <Roles />}
          {this.props.title === "Roles" && <Roles2 />}
          <Title>{this.props.title}</Title>
        </IconAndTitleWrapper>
        {this.props.schema.summaryTooltip ? (
          <TooltipWrapper>
            <Tooltip tooltip={this.props.schema.summaryTooltip} />
          </TooltipWrapper>
        ) : (
          " "
        )}
        {this.renderDataStructure()}
        {this.props.schema.locked ? (
          <div />
        ) : (
          <PenFrame
            type="button"
            onClick={() => this.props.changeEditState(this.props.title)}
          >
            <IconPen />
          </PenFrame>
        )}
      </Frame>
    );
  }

  renderDataStructure = () => {
    const data = this.manipulateDatastructure(this.props.data);
    return Object.keys(data).map((dataKey) => {
      if (typeof data[dataKey] === "object" && data[dataKey].length) {
        const titles = Object.keys(this.clearKeys(data[dataKey][0]));
        return [
          <ArrayEntryFrame key={dataKey + "-frame"} elements={titles.length}>
            {titles.map((title) => {
              if (title === "preFilled") return null;
              return (
                <TitleSmall key={title} style={{ fontWeight: 600 }}>
                  {title}
                </TitleSmall>
              );
            })}
            {data[dataKey].map((elem: { [index: string]: any }) =>
              Object.keys(this.clearKeys(elem)).map((key) => (
                <Text key={key + "-text"}>
                  {key === "birthDate" ? elem[key].split("T")[0] : elem[key]}
                </Text>
              ))
            )}
          </ArrayEntryFrame>,
        ];
      }

      if (typeof data[dataKey] === "object" && data[dataKey].length === 0) {
        return [
          <Title
            key={dataKey + "-title"}
            style={{ marginTop: 10, fontSize: 14 }}
          >
            {dataKey}
          </Title>,
          <ArrayEntryFrame elements={0} key={dataKey + "-frame"}>
            <Text>No entries</Text>
          </ArrayEntryFrame>,
        ];
      }
      return (
        <EntryFrame key={dataKey}>
          <TitleSmall style={{ fontWeight: 600 }}>{dataKey}</TitleSmall>
          <TitleSmall style={{ fontWeight: 600, marginRight: 10 }}>
            :
          </TitleSmall>
          <Text>{data[dataKey] || "No data"}</Text>
        </EntryFrame>
      );
    });
  };

  clearKeys(data: { [index: string]: any }) {
    const newData: { [index: string]: any } = JSON.parse(JSON.stringify(data));
    delete newData.roleCode;
    delete newData.id;
    delete newData.address;
    if (newData.share) {
      delete newData.share;
      newData.share = data.share;
    }

    return newData;
  }

  manipulateDatastructure(data: { [index: string]: any }) {
    const dataCopy: { [index: string]: any } = JSON.parse(JSON.stringify(data));
    if (
      dataCopy.postalPlace &&
      dataCopy.postalCode &&
      dataCopy.address &&
      dataCopy.countryCode
    ) {
      ["postalPlace", "postalCode", "address", "countryCode"].forEach(
        (key) => delete dataCopy[key]
      );
      dataCopy.address = `${data.address}, ${data.postalCode}, ${data.postalPlace}, ${data.countryCode}`;
    }
    return dataCopy;
  }
}

const IconAndTitleWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const PenFrame = styled.button`
  background: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  position: absolute;
  top: 5px;
  right: 10px;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;

const EntryFrame = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
`;

type ArrayEntryFrameProps = {
  elements: number;
};

const ArrayEntryFrame = styled(EntryFrame)<ArrayEntryFrameProps>`
  display: grid;
  grid-template-columns: 2fr ${(props) => `repeat(${props.elements - 1}, 1fr)`};
  grid-row-gap: 5px;
  margin-top: 5px;
  margin-bottom: 20px;
`;

const Frame = styled.div`
  width: 92%;
  height: auto;
  padding: 20px;
  padding-top: 15px;
  min-height: 40px;
  background-color: #ffffff;
  position: relative;
  margin-bottom: 20px;
  border: 1px solid #cccccc;
`;

const TooltipWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-top: 5px;
`;
