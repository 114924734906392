import React, { Component } from "react";
import styled from "styled-components";
import { Button } from "../../../../styles/base";
import { Title, TitleBig, Text, TitleSmall } from "../../../../styles/fonts";
import { LIGHTGRAY, COALOPACITY, PINK } from "../../../../styles/colors";
import { Task } from "../../../../store/flow/types";
import Pen from "../../../../icons/Pen";
import ProgressDone from "../../../../icons/ProgressDone";
import { connect } from "react-redux";
import { throwError } from "../../../../store/error/actions";
import { updateContentTitle } from "../../../../store/content-title/actions";
import { updateOnboardingStatus } from "../../../../store/onboarding-status/actions";
import { Status } from "../../../../store/onboarding-status/types";
import { getI18n } from "react-i18next";
import { Location } from "history";
import jwtDecode from "jwt-decode";

type Props = {
  task: Task;
  redirectUrl: string;
  location: Location;
  throwError: (error: string) => void;
  updateContentTitle: (title: string) => void;
  updateStatus: (status: Status[]) => void;
  onComplete: () => void;
};

class SignAgreement extends Component<Props> {
  render() {
    let token = this.props.location && this.props.location.search;
    let decoded: any = undefined;
    let signingSuccess = false;
    // naively just grabs the entire string after query param.
    // this is safe as all other params are never accounted for when creating redirectURL that is sent to signicat
    if (!!token && token.includes("?idfy-jwt=")) {
      token = token.split("?idfy-jwt=")[1];
      decoded = jwtDecode<any>(token); // Returns with the JwtPayload type
      const success = !!decoded.SignSuccess;
      const aborted = !!decoded.Aborted;
      const error = !!decoded.Error;
      if (success && !aborted && !error) signingSuccess = true;
    }

    if (!this.props.task.context.signers) {
      this.props.throwError("no-signers");
      return <div />;
    }
    return (
      <SignAgreementFrame>
        <Pen />
        <TitleBig style={{ marginTop: 30 }}>Sign</TitleBig>
        <Text style={{ marginTop: 20, marginBottom: 20 }}>
          To approve and complete the onboarding, we need all of the signatures
          mentioned below.
        </Text>
        {this.props.task.context.signers.map((signer) => {
          if (
            !signer.hasCompletedSigning &&
            signingSuccess &&
            decoded &&
            decoded.ExternalSignerId === signer.id
          )
            signer.hasCompletedSigning = true;
          return (
            <SignerFrame key={signer.id}>
              <Title
                style={{
                  flexGrow: 1,
                  marginLeft: 20,
                  color: signer.hasCompletedSigning ? COALOPACITY : "#000",
                }}
              >
                {signer.name || "Name not found"}
              </Title>
              {signer.hasCompletedSigning ? (
                <ProgressDoneFrame>
                  <ProgressDone big />
                </ProgressDoneFrame>
              ) : (
                <StyledButton
                  onClick={() =>
                    window.location.replace(signer.signingUrl || "")
                  }
                >
                  <TitleSmall style={{ color: "white" }}>Sign</TitleSmall>
                </StyledButton>
              )}
            </SignerFrame>
          );
        })}
        {this.props.redirectUrl && (
          <>
            <Text style={{ marginTop: "5%" }}>
              waiting-for-pending-signatures
            </Text>
            <RedirectLink href={this.props.redirectUrl}>
              {getI18n().t("go-back-to-economy-system")}
            </RedirectLink>
          </>
        )}
      </SignAgreementFrame>
    );
  }

  componentDidMount() {
    this.props.updateContentTitle("");
    this.props.updateStatus([
      { name: "Register", state: "done" },
      { name: "Sign", state: "active" },
      { name: "Done", state: "todo" },
    ]);
  }
}

const StyledButton = styled(Button)`
  margin: 20px;
`;

const RedirectLink = styled.a`
  color: ${PINK};
  text-decoration: none;
  padding-bottom: 10%;
  font-size: 14px;
  line-height: 21px;
  white-space: pre-wrap;

  @media (max-width: 700px), (max-height: 700px) {
    font-size: 12px;
  }
`;

const ProgressDoneFrame = styled.div`
  height: 70px;
  width: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 0px;
`;

const SignerFrame = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 50%;
  justify-content: space-evenly;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  height: 80px;
  @media (max-width: 500px) {
    width: 100%;
  }
`;

const SignAgreementFrame = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 60px;

  @media (max-width: 700px), (max-height: 700px) {
    width: 90%;
  }
`;

const mapDispatchToProps = (dispatch: any) => {
  return {
    throwError: (error: string) => dispatch(throwError(error)),
    updateContentTitle: (title: string) => dispatch(updateContentTitle(title)),
    updateStatus: (status: Status[]) =>
      dispatch(updateOnboardingStatus(status)),
  };
};

export default connect(undefined, mapDispatchToProps)(SignAgreement);
