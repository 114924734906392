import React, { Component } from "react";
import { signinRedirectCallback } from "../auth/index";

export default class Callback extends Component {
  componentDidMount() {
    signinRedirectCallback()
      .then(() => {
        window.location.assign(`/onboarding/${localStorage.getItem('flowID')}`);
      })
      .catch(error => {
        console.error("Error in signinRedirectCallback: ", error);
      });
  }
  render() {
    return <div />;
  }
}
