import React, { Component } from "react";
import styled from "styled-components";
import { SNOW } from "../../../../styles/colors";
import { TitleBig, Text } from "../../../../styles/fonts";
import { Button } from "../../../../styles/base";
import { connect } from "react-redux";
import { updateContentTitle } from "../../../../store/content-title/actions";
import { updateOnboardingStatus } from "../../../../store/onboarding-status/actions";
import { updateFlow } from "../../../../store/flow/actions";
import { throwError } from "../../../../store/error/actions";
import InboxSpinner from "../../../../icons/InboxSpinner";
import SignerForm from "./SignerForm";





class FixSignerDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmitting: false,
            errors: [],
            signers: this.props.task.context.signers
        }
    };

    render() {
        return (<>
            <TitleBig key="title" style={{ marginTop: 40, textAlign: "center" }}>update-signer-number-header</TitleBig>
            <Text key={0} style={{ textAlign: "center", fontSize: 16, marginTop: 20, width: "60%", color: "#777", paddingBottom: "2em" }}>update-signer-number</Text>
            <Frame key="frame">
                {this.state.isSubmitting ?
                    <SpinnerWrapper>
                        <InboxSpinner />
                    </SpinnerWrapper>
                    :
                    this.state.signers.map(signer => {
                        return (
                            <SignerForm key={signer.id}
                                onBlur={this.handleSignerChange}
                                availableSigner={{ ...signer }}
                            />
                        );
                    })
                }
                < Text style={{ fontSize: 12, color: "#777", display: this.state.isSubmitting?"none":"initial" }}>fix-signer-phonenumber-info</Text>
                <Button style={{ ...ButtonStyle(this.state.isSubmitting), display: "block", margin: "40px auto 40px auto", }} key="btn" onClick={() => this.onSubmit()} disabled={this.state.isSubmitting}>
                    <Text color={SNOW}>Neste</Text>
                </Button>
            </Frame>
        </>
        );
    }


    async onSubmit() {
        this.setState({ isSubmitting: true })
        const result = await this.props.completeTask(0, {
            signers: this.state.signers,
        })
        if (result && result.data && result.data.errors) {
            return this.setState({ isSubmitting: false })
        } else this.props.refresh()
    }

    handleSignerChange = (signer) => {

        const signerIndex = this.state.signers.findIndex(e => e.id === signer.id)
        const { signers } = this.state

        signers[signerIndex].mobile = signer.mobile
        this.setState({ signers: signers });
    };

    componentDidMount() {
        this.props.updateStatus([
            { name: "Register", state: "done" },
            { name: "Sign", state: "active" },
            { name: "Done", state: "todo" }
        ]);
    }
}

const SpinnerWrapper = styled.div`
    > svg {
      margin: 0 auto;
      display: block;
    }
`;

const Frame = styled.div`
    width: 60%;
    margin: 0 auto;
    @media (max-width: 700px), (max-height: 700px) {
        width: 90%;
    }
`;

const ButtonStyle = (isSubmitting) => ({
    backgroundColor: isSubmitting ? "#cccccc" : "#FFA900",
    display: isSubmitting ? "none" : "inherit"
})

const mapDispatchToProps = (dispatch) => {
    return {
        throwError: (error) => dispatch(throwError(error)),
        updateContentTitle: (title) => dispatch(updateContentTitle(title)),
        updateStatus: (status) => dispatch(updateOnboardingStatus(status)),
        updateFlow: (flow) => dispatch(updateFlow(flow))
    };
};

export default connect(undefined, mapDispatchToProps)(FixSignerDetails);
