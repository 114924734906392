import { Reducer } from "redux";
import { FlowState, FlowActions } from "./types";

export const initialState: FlowState = {
  tasks: [],
  status: "",
  redirectUrl: ""
};

const reducer: Reducer<FlowState> = (state: FlowState = initialState, action) => {
  switch ((action as FlowActions).type) {
    case "@@flow/UPDATE_FLOW":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default reducer;
