import React from "react";

export default class ZtlIcon extends React.Component {
  render() {
    return (
      <svg width="46" height="36" viewBox="0 0 146 106" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M138.5 88.6H135.9C129.6 88.6 124.4 83.5 124.4 77.1V0H107.5V77.1C107.5 83.4 102.4 88.6 96 88.6H94.9C88.6 88.6 83.4 83.5 83.4 77.1V49H94V32H83.4V0H66.5V32H60.8L54.3 49H66.6V77.1C66.6 83.4 61.5 88.6 55.1 88.6H28.4L50.2 32H7.40002L0.900024 49H25.4L3.60003 105.5H55.3C62.6 105.4 69.6 102.6 74.9 97.6C78.1 100.5 82 105.5 95.3 105.5C107.2 105.5 112.4 100.7 115.9 97.6C119.3 101.1 124.8 105.5 135.8 105.5H145.2V88.6H138.5Z" fill="#ffffff"/>
      </svg>

  );
  }
}
