
import React from 'react';

type SelectProps = {
    color?: string;
};

export default class Pen extends React.Component<SelectProps> {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 64 64" width="64" height="64">
                <title>sign</title>
                    <g className="nc-icon-wrapper" strokeLinecap="square" strokeLinejoin="miter" strokeWidth="2" fill="#ed3878" stroke="#ed3878">
                    <line data-color="color-2" fill="none" strokeMiterlimit="10" x1="2" y1="62" x2="62" y2="62" /> 
                    <path fill="none" stroke="#ed3878" strokeMiterlimit="10" d="M5,55 c0,0,3.239-13.239,6-16L45.929,4.071c2.761-2.761,7.239-2.761,10,0l0,0c2.761,2.761,2.761,7.239,0,10L21,49C18.239,51.761,5,55,5,55 z" />
                    <polyline data-color="color-2" fill="none" strokeMiterlimit="10" points=" 49,38 61,26 57,22 " /> 
                    <path data-cap="butt" fill="none" stroke="#ed3878" strokeMiterlimit="10" d="M10.792,39.227 C13.208,38.963,15.92,39.92,18,42l0,0c2.081,2.081,3.028,4.8,2.764,7.216" strokeLinecap="butt" />
                </g>
            </svg>
        );
    }
}