import React from "react";
import styled from "styled-components";
import { BROWN, LIGHTGRAY, PINK } from "../../../../styles/colors";
import { TitleBig, Text, Title, TitleSmall } from "../../../../styles/fonts";
import { Button } from "../../../../styles/base";
import Signer from "./Signer";
import { AvailableSigner } from "./types";
import { SimpleRule } from "../../../../store/flow/types";
import { Status } from "../../../../store/onboarding-status/types";
import { connect } from "react-redux";
import { updateContentTitle } from "../../../../store/content-title/actions";
import { updateOnboardingStatus } from "../../../../store/onboarding-status/actions";
import { updateFlow } from "../../../../store/flow/actions";
import { throwError } from "../../../../store/error/actions";
import { Task, FlowState } from "../../../../store/flow/types";
import InboxSpinner from "../../../../icons/InboxSpinner";
import _ from "lodash";

export interface SelectSignersProps {
  task: Task;
  language: string;
  updateContentTitle: (title: string) => void;
  updateStatus: (status: Status[]) => void;
  throwError: (error: string) => void;
  updateFlow: (flow: FlowState) => void;
  completeTask: (counter: number, data: any) => any;
  refresh: () => void;
}

export interface SelectSignersState {
  companyType: string;
  selectedSigners: AvailableSigner[];
  availableSigners: AvailableSigner[];
  waitingForSignUrl: boolean;
  errors: Array<string>;
  isSubmitting: boolean;
  hasSigningRule: boolean;
  currentSigningRule: SimpleRule;
  hasOtherSigningRule: boolean;
  currentSigningRuleIsdefault: boolean;
  customRule: SimpleRule;
  reason: string;
}

const determineReason = (companyType: String, reason: String) => {
  // there exists two reasons but for now we translate these to the same.
  // other reason is for cases where signing rule exists, but we could not process it
  if ((companyType === "AS" || companyType === "ASA") && !!reason)
    return "signing-rule-not-found-AS";
  if (companyType === "ENK" && !!reason) return "signing-rule-not-found-ENK";
  if (companyType === "FLI" && !!reason) return "signing-rule-not-found-FLI";
  if (companyType === "BRL" && !!reason) return "signing-rule-not-found-BRL";
  if (companyType === "SAM" && !!reason) return "signing-rule-not-found-SAM";
  if (companyType === "ESEK" && !!reason) return "signing-rule-not-found-ESEK";
  if (companyType === "SA" && !!reason) return "signing-rule-not-found-SA";
  if (companyType === "DA" && !!reason) return "signing-rule-not-found-DA";
  if (companyType === "STI" && !!reason) return "signing-rule-not-found-STI";
  if (companyType === "NUF" && !!reason) return "signing-rule-not-found-NUF";
  if (companyType === "ANS" && !!reason) return "signing-rule-not-found-ANS";
  return "";
};

class SelectSigners extends React.Component<
  SelectSignersProps,
  SelectSignersState
> {
  static key = 0;
  interval = 0;

  constructor(props: SelectSignersProps) {
    super(props);
    const hasSigningRule =
      Object.keys(this.props.task.context.signRules).length > 0;
    const hasOtherSigningRule = !(
      this.props.task.context.signRules.defaultRule.ruleDescriptor ===
      this.props.task.context.signRules.customRule.ruleDescriptor
    );
    const currentSigningRule = hasOtherSigningRule
      ? this.props.task.context.signRules.customRule
      : this.props.task.context.signRules.defaultRule;
    const companyType = this.props.task.context.companyType;
    const reason = determineReason(
      companyType,
      this.props.task.context.signRules.customRule.reason || ""
    );
    const signers = currentSigningRule.signers || [];
    const customRule =
      this.props.task.context &&
      this.props.task.context.signRules &&
      this.props.task.context.signRules.customRule;

    this.state = {
      customRule: customRule,
      selectedSigners: [],
      availableSigners: this.createCurrentSigners(signers),
      waitingForSignUrl: false,
      errors: [],
      isSubmitting: false,
      hasSigningRule: hasSigningRule,
      currentSigningRule: currentSigningRule,
      currentSigningRuleIsdefault: currentSigningRule.isDefault,
      hasOtherSigningRule: hasOtherSigningRule,
      reason: reason,
      companyType: companyType,
    };
  }

  render() {
    if (this.state.waitingForSignUrl)
      return (
        <div style={{ marginTop: 50 }}>
          <InboxSpinner />
        </div>
      );
    const uniqueSigners = this.state.availableSigners;
    /* const obligatorySigners = this.state.availableSigners.filter((e, i) => e.obligatory);
        obligatorySigners.map(
            signer => ((uniqueSigners.find(signer2 => signer2.id === signer.id) || { obligatory: false }).obligatory = true)
        ); */
    return [
      <TitleBig style={{ marginTop: 40 }} key="start">
        Select signees
      </TitleBig>,
      <Text
        key={0}
        style={{
          textAlign: "left",
          fontSize: 16,
          marginTop: 20,
          color: BROWN,
        }}
      >
        {this.state.reason}
      </Text>,
      <Text key={1} style={{ margin: 30, marginTop: 10, fontSize: 16 }}>
        {this.state.reason ? "" : this.getSignRuleText()}
      </Text>,
      <Title style={{ textAlign: "left", paddingBottom: "2em" }}>
        {"select-signees"}
      </Title>,
      <Frame>
        {!this.state.isSubmitting ? (
          <StyledTable>
            <TableHeader>
              <HeaderCell style={{ flex: 0 }}>
                <div style={{ width: 40, height: 1 }} />
              </HeaderCell>
              <HeaderCell style={{ flex: 2 }}>
                <TitleSmall>Name</TitleSmall>
              </HeaderCell>
              <HeaderCell style={{ flex: 1 }}>
                <TitleSmall>Role</TitleSmall>
              </HeaderCell>
            </TableHeader>
            {uniqueSigners.map((signer) => {
              const active = this.state.selectedSigners.includes(signer);
              return (
                <DataRow>
                  <Signer
                    onSignerChange={(signer: AvailableSigner) =>
                      this.handleSignerChange(signer)
                    }
                    key={
                      signer.id + this.state.currentSigningRule.ruleDescriptor
                    }
                    fromBisnode={signer.customSigner ? false : true}
                    onClick={() => this.onSelectedSignerChange(signer)}
                    signer={signer}
                    active={active}
                  />
                </DataRow>
              );
            })}
            <tfoot>
              {uniqueSigners.some((s) => s.obligatory) ? (
                <Text style={{ fontSize: 12, color: BROWN }}>
                  signers-obligatory-footnote
                </Text>
              ) : null}
              <Text style={{ fontSize: 12, color: BROWN }}>
                signers-obligatory-contactinfo
              </Text>
            </tfoot>
          </StyledTable>
        ) : this.state.isSubmitting ? (
          <div>
            <SpinnerWrapper>
              <InboxSpinner />
            </SpinnerWrapper>
          </div>
        ) : (
          <Text style={{ fontSize: 14 }}>contact-support</Text>
        )}
        {this.state.errors.length > 0 && !this.state.isSubmitting ? (
          <ErrorBox>
            <Text
              style={{
                fontSize: 14,
                color: "white",
                fontWeight: "bold",
              }}
            >
              {this.state.errors[0]}
            </Text>
          </ErrorBox>
        ) : (
          ""
        )}
        {this.state.hasSigningRule ? (
          <Button
            style={{
              ...ButtonStyle(this.state.isSubmitting),
              display: "block",
              width: "90px",
              margin: "0px",
              marginTop: "30px",
            }}
            key="btn"
            onClick={() => this.onSubmit()}
            disabled={this.state.isSubmitting}
          >
            <Text color={"#FFFFFF"}>Neste</Text>
          </Button>
        ) : null}
        {this.state.hasOtherSigningRule ? this.showSiginignRuleText() : ""}
      </Frame>,
    ];
  }

  showSiginignRuleText = () => {
    return (
      <ChangeSignRuleWrapper>
        <ChangeSignRuleInner>
          <Text>
            {this.state.currentSigningRuleIsdefault
              ? "sign-with-default-brreg-rule-text"
              : `text-for-switch-signing-rule-button`}
          </Text>
        </ChangeSignRuleInner>
        <ChangeSignRuleInner>
          <ChangeSigningRuleButton
            onClick={() => {
              this.updateCurrentSigningRule();
            }}
          >
            <Text>
              {this.state.currentSigningRuleIsdefault
                ? this.state.customRule.ruleDescriptor
                : "Sign with entire board"}
            </Text>
          </ChangeSigningRuleButton>
        </ChangeSignRuleInner>
      </ChangeSignRuleWrapper>
    );
  };

  createCurrentSigners = (signers: any[]) => {
    return signers.map((signer) => ({
      ...signer,
      email: "",
      emailIsValid: false,
      mobile: {
        phonenumber: "",
        isPossiblePhonenumber: false,
      },
    }));
  };

  updateCurrentSigningRule = () => {
    //empty selected signers
    let signers = [];
    if (this.state.currentSigningRuleIsdefault) {
      this.setState({
        currentSigningRule: this.props.task.context.signRules.customRule,
      });
      this.setState({ currentSigningRuleIsdefault: false });
      signers = this.createCurrentSigners(
        this.props.task.context.signRules.customRule.signers
      );
    } else {
      this.setState({
        currentSigningRule: this.props.task.context.signRules.defaultRule,
      });
      this.setState({ currentSigningRuleIsdefault: true });
      signers = this.createCurrentSigners(
        this.props.task.context.signRules.defaultRule.signers
      );
    }
    this.setState({ selectedSigners: [] });
    this.setState({ availableSigners: signers });
  };

  async onSubmit() {
    const selectedSigners = _.cloneDeep(this.state.selectedSigners);

    if (selectedSigners.length === 0)
      return this.setState({ errors: ["signers-error-106"] });

    // Check that phonenumber is valid. Same as used in backend
    if (
      !selectedSigners.every(
        (signer) => signer.mobile.isPossiblePhonenumber && signer.emailIsValid
      )
    ) {
      return this.setState({ errors: ["signers-error-contactinfo"] });
    }

    this.setState({ isSubmitting: true });
    const deepClone = JSON.parse(JSON.stringify(this.state.currentSigningRule));
    delete deepClone.signers;
    const result = await this.props.completeTask(0, {
      signers: selectedSigners,
      rule: deepClone,
      language: this.props.language,
    });
    if (result && result.data && result.data.errors) {
      let localErr = JSON.parse(result.data.errors);
      if (typeof localErr.code === "string") {
        this.setState({ errors: [localErr.code] });
      } else {
        this.setState({ errors: result.data.errors });
      }
      return this.setState({ isSubmitting: false });
    } else this.props.refresh();
  }

  handleSignerAdd = () => {
    const newSigner: AvailableSigner = {
      email: "",
      emailIsValid: false,
      mobile: {
        phonenumber: "",
        isPossiblePhonenumber: false,
      },
      name: "",
      id: `${SelectSigners.key * 10}`,
      obligatory: false,
      customSigner: true,
      birthdate: "",
      roles: [],
    };
    SelectSigners.key = SelectSigners.key + 1;

    this.setState({
      availableSigners: [...this.state.availableSigners, newSigner],
      selectedSigners: [...this.state.selectedSigners, newSigner],
    });
  };

  handleSignerChange = (signer: AvailableSigner) => {
    const selectedSigners = this.state.selectedSigners.map((sSigner) =>
      sSigner.id === signer.id ? signer : sSigner
    );
    const availableSigners = this.state.availableSigners.map((aSigner) =>
      aSigner.id === signer.id ? signer : aSigner
    );

    this.setState({ selectedSigners, availableSigners });
  };

  onSelectedSignerChange(selectedSigner: AvailableSigner) {
    if (
      this.state.selectedSigners.find(
        (signer) => signer.name === selectedSigner.name
      )
    ) {
      const newSigners = this.state.selectedSigners.filter(
        (signer) => signer.name !== selectedSigner.name
      );
      return this.setState({ selectedSigners: newSigners });
    }
    return this.setState({
      selectedSigners: [...this.state.selectedSigners, selectedSigner],
    });
  }

  getSignRuleText() {
    return <Title>{this.state.currentSigningRule.ruleDescriptor}</Title>;
  }

  componentDidMount() {
    this.props.updateStatus([
      { name: "Register", state: "done" },
      { name: "Sign", state: "active" },
      { name: "Done", state: "todo" },
    ]);
  }
}

const ErrorBox = styled.div`
  padding: 10px;
  background-color: ${PINK};
  margin-top: 20px;
`;

const TableHeader = styled.tr`
  flex: 1;
  display: flex;
  border-bottom: 1px solid ${LIGHTGRAY};
  @media (max-width: 700px), (max-height: 700px) {
    width: 90%;
  }
`;

const Frame = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 60%;
  margin: 0;
  @media (max-width: 700px), (max-height: 700px) {
    width: 90%;
  }
`;

const StyledTable = styled.table`
  display: inline-block;
  width: 100%;
  > tr {
    border-bottom: 1px solid ${LIGHTGRAY};
  }
`;

const DataRow = styled.tr`
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
`;

const HeaderCell = styled.td`
  display: block;
  text-align: left;
`;

const SpinnerWrapper = styled.div`
  > svg {
    margin: 0 auto;
    display: block;
  }
`;

const ChangeSignRuleWrapper = styled.div`
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
`;

const ChangeSignRuleInner = styled.div`
  display: flex;
  margin: auto;
`;

const ChangeSigningRuleButton = styled.button`
  p {
    color: BROWN;
  }
  border: none;
  background-color: inherit;
  cursor: pointer;
`;

const ButtonStyle = (isSubmitting: boolean) => ({
  backgroundColor: isSubmitting ? "#cccccc" : PINK,
  display: isSubmitting ? "none" : "inherit",
});

const mapDispatchToProps = (dispatch: any) => {
  return {
    throwError: (error: string) => dispatch(throwError(error)),
    updateContentTitle: (title: string) => dispatch(updateContentTitle(title)),
    updateStatus: (status: Status[]) =>
      dispatch(updateOnboardingStatus(status)),
    updateFlow: (flow: FlowState) => dispatch(updateFlow(flow)),
  };
};

export default connect(undefined, mapDispatchToProps)(SelectSigners);
