import React, { Component, ReactElement } from "react";
import styled from "styled-components";

type Props = { children: ReactElement | [ReactElement]; minHeight?: string; };

class Header extends Component<Props> {
  render() {
    return <HeaderFrame minHeight={this.props.minHeight} >{this.props.children}</HeaderFrame>;
  }
}

type HeaderFrameProps = {
  minHeight?: string;
}

const HeaderFrame = styled.div<HeaderFrameProps>`
  width: 100%;
  min-height: ${props => props.minHeight || "135px"};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;

  @media (max-width: 700px), (max-height: 700px) {
    min-height: ${props => props.minHeight || "80px"};
  }
`;

export default Header;
