export const SNOW = "#fdfaf5";
export const PINK = "#ed3878";
export const DEEPRED = "#450101";
export const LIGHTGRAY = "#F9F9F9";
export const MEDIUMGRAY = "#E2E2E2";
export const GRAY = "#6C6C6C";
export const DARKGRAY = "#484848";
export const NOIR = "#1A1A1A";
export const COALOPACITY = "#707071";
export const COAL2 = "#2B2B2B";
export const COAL = "#20242C";
export const BROWN = "#460000"

export const RED = "#4d0000";