import React from "react";

export default class Globe extends React.Component {
  render() {
    return (
      <svg width="129" height="109" viewBox="0 0 129 109" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M93.6888 54.7148C92.6957 52.3586 87.741 50.2961 85.8774 49.5915L83.4844 47.4065C83.4027 47.3315 83.3106 47.2669 83.2118 47.2143C81.3482 46.2278 77.2891 45.7602 76.0616 45.6364C75.3214 45.1926 73.3393 44.0521 72.0644 43.7333C70.5143 43.3448 67.0057 44.409 65.5688 44.8831C65.3344 44.9608 65.1263 45.103 64.9669 45.294C63.6328 46.9074 59.2773 52.3823 59.2773 55.1138C59.2773 56.4822 60.597 57.7242 62.1261 59.1624C62.8215 59.8169 63.9844 60.9114 64.1069 61.2683C64.1425 61.7622 64.2768 62.4115 64.4348 63.1622C64.7786 64.8098 65.3528 67.5677 64.6521 68.6542C62.8228 71.4898 62.3078 76.588 63.5274 79.7884C64.2939 81.7969 65.5227 82.5134 66.4183 82.761C66.7686 82.8584 67.1321 82.9019 67.5075 82.9019C70.0941 82.9019 73.201 80.7643 75.9826 78.8507C77.2733 77.9617 78.4942 77.1227 79.2054 76.8449C81.6195 75.9005 82.7745 72.7752 83.6201 70.4941C83.8426 69.8936 84.0942 69.2113 84.2377 68.9861C84.8567 68.0128 86.5399 66.3982 89.2451 65.0469C91.9411 63.7022 94.9057 57.6096 93.6888 54.7148Z"
          fill="#484E57"
        />
        <path
          d="M63.5136 11.8162C41.0015 11.8162 22.6855 30.1321 22.6855 52.6442C22.6855 75.1562 41.0015 93.4722 63.5136 93.4722C86.0256 93.4722 104.342 75.1562 104.342 52.6442C104.342 30.1321 86.0256 11.8162 63.5136 11.8162ZM63.5136 90.8381C54.2351 90.8381 45.7218 87.51 39.0971 81.989L41.6758 78.1209C42.7189 77.7758 44.4034 77.0685 45.0211 75.957C46.8294 72.7026 44.5404 67.9178 43.8582 66.6337C43.8002 65.8527 43.6145 63.8627 43.1838 62.784C42.6636 61.4854 40.5116 60.229 39.3025 59.6073C38.4188 58.0954 36.6553 54.6671 36.6553 52.6429C36.6553 49.7849 39.0168 49.3147 39.828 49.2265C42.819 48.7102 44.5983 46.5687 45.2766 45.577C46.541 45.1924 49.341 44.1783 50.2892 42.5215C51.3033 40.7474 50.1628 36.6383 49.499 34.6193C49.6729 33.7276 49.9797 31.569 49.3397 29.7568C48.6917 27.9208 47.0572 26.4998 46.3421 25.9453C45.9259 25.001 44.6734 22.3761 42.9771 20.4664C47.4905 17.5755 52.6454 15.6079 58.1822 14.8308C57.5514 16.1808 57.0311 17.5979 57.4486 18.7477C57.9135 20.0239 59.7574 20.9669 61.2996 21.5504C61.1021 23.057 61.0691 25.5502 62.6904 26.8461C64.2774 28.1158 66.2675 27.5705 67.5832 27.207C67.7505 27.1609 67.9612 27.103 68.1363 27.0542C68.3378 28.0868 68.4432 30.4087 68.3695 32.62C68.3576 32.9624 68.4801 33.2956 68.7106 33.5485C68.8568 33.7105 72.3271 37.4996 75.9569 38.0093C76.1426 38.0356 76.3283 38.0475 76.5179 38.0475C79.8132 38.0475 83.6378 34.2768 84.9101 32.9177C86.2943 32.5858 90.8802 31.436 94.2571 30.0123C98.9352 36.3512 101.708 44.1796 101.708 52.6442C101.708 73.7048 84.5742 90.8381 63.5136 90.8381Z"
          fill="#484E57"
        />
        <path
          d="M18.5604 43.5127C7.68315 45.8247 1 48.9613 1 52.4157C1 59.5247 29.3035 65.2876 64.2176 65.2876C99.1317 65.2876 127.435 59.5247 127.435 52.4157C127.435 48.9613 120.752 45.8247 109.875 43.5127"
          stroke="#FDBD4C"
          stroke-width="2"
        />
        <path
          d="M24.2335 78.9862C18.1769 88.3124 15.6691 95.2561 18.1118 97.6987C23.1386 102.726 47.2272 86.787 71.9152 62.099C96.6032 37.411 112.542 13.3224 107.515 8.29557C105.072 5.85292 98.1287 8.3607 88.8024 14.4173"
          stroke="#FDBD4C"
          stroke-width="2"
        />
        <path
          d="M39.6319 14.4171C30.3057 8.36052 23.3621 5.85274 20.9195 8.29539C15.8927 13.3222 31.8312 37.4108 56.5192 62.0988C81.2072 86.7868 105.296 102.725 110.323 97.6985C112.765 95.2559 110.257 88.3123 104.201 78.986"
          stroke="#FDBD4C"
          stroke-width="2"
        />
      </svg>
    );
  }
}
