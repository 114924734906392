import React, { Component, ReactNode } from "react";
import { match } from "react-router-dom";
import { connect } from "react-redux";
import styled from "styled-components";
import Attention from "../../icons/Attention";
import { Title, Text } from "../../styles/fonts";
import { ErrorState, AppError } from "../../store/error/types";
import { Status } from "../../store/onboarding-status/types";
import { updateOnboardingStatus } from "../../store/onboarding-status/actions";
import { clearError, throwError } from "../../store/error/actions";
import { FlowState } from "../../store/flow/types";
import {getI18n} from "react-i18next";
import {PINK} from "../../styles/colors";

type ErrorHandlerProps = {
  error: AppError;
  flow: FlowState;
  match: match<{ flowId: string }>;
  updateStatus: (errors: Status[]) => void;
  clearError: () => void;
  throwError: (error: string, meta?: object) => void;
};

class ErrorHandler extends Component<ErrorHandlerProps> {
  interval = 0;

  getTitle = (props: ErrorHandlerProps) => {
    if (props.error.title !== undefined) {
      return (
        <Title
          className="text"
          style={{ fontSize: 24, marginBottom: 10, paddingTop: 40 }}
        >
          {this.props.error.title}
        </Title>
      );
    }

    return null;
  };

  getDisplayIcon = (props: ErrorHandlerProps): ReactNode => {
    if (props.error.icon !== undefined) {
      return props.error.icon;
    }

    return <Attention />;
  };

  render() {
    return (
      <Frame>
        {this.getTitle(this.props)}
        <div style={{ height: 1, width: 1, marginTop: 50 }} />
        {this.getDisplayIcon(this.props)}
        <Title
          className="text"
          style={{ fontSize: 18, marginBottom: 10, paddingTop: 40 }}
        >
          {this.props.error.mainText}
        </Title>
        <Text className="text" style={{ paddingTop: 10, width: "80%" }}>
          {this.props.error.description}
        </Text>
        {this.props.error.moreInfoUrl ? (
          <div>
            <Text>{this.props.error.moreInfoText}</Text>
            <Link href={getI18n().t(this.props.error.moreInfoUrl)}>
              <Text>{this.props.error.moreInfoUrl}</Text>
            </Link>
          </div>
        ) : null}
        {this.props.error.redirectUrl ? (
          <Link href={this.props.error.redirectUrl}>
            <Text>redirect-instruction</Text>
          </Link>
        ) : null}
      </Frame>
    );
  }

  componentDidMount() {
    if (
      this.props.error.mainText === "Takk!" ||
      this.props.error.mainText === "Already onboarded!"
    ) {
      this.props.updateStatus([
        { name: "Register", state: "done" },
        { name: "Sign", state: "done" },
        { name: "Done", state: "done" },
      ]);
    }

    clearInterval(this.interval);
    // In case of errors, poll every 5s
    // we prevent unnecessary polling by using the abortPolling prop.
    // err must also have a refresh function passed on prop
    if (this.props.error.refresh && this.props.error.abortPolling === false)
      this.interval = setInterval(() => this.props.error.refresh(), 5000);
  }
}

const Frame = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 50px;

  @media (max-width: 700px), (max-height: 700px) {
    .text {
      margin-top: -10px !important;
    }
  }
`;

const Link = styled.a`
  text-decoration: none;
  margin: 15px 0 15px 0;
  > p {
    color: ${PINK};
    font-size: 16px;
  }
`;

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateStatus: (status: Status[]) =>
      dispatch(updateOnboardingStatus(status)),
    clearError: () => dispatch(clearError()),
    throwError: (error: string, meta?: object) =>
      dispatch(throwError(error, meta)),
  };
};

const mapStateToProps = ({
  error,
  flow,
}: {
  error: ErrorState;
  flow: FlowState;
}) => {
  return { error: error.errorData, flow: flow };
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorHandler);
