import React from "react";
import styled from "styled-components";
import { FieldTemplateProps } from "react-jsonschema-form";
import Tooltip from "../../tooltip";

import { TextSmall, Title, Text } from "../../../styles/fonts";
import { LIGHTGRAY } from "../../../styles/colors";
import { JSONSchema6 } from "json-schema";

interface CustomSchema extends JSONSchema6 {
  helperText: string;
  tooltip?: string;
  "x-styling"?: {
    titleType?: string;
  };
}

interface CustomFieldTemplateProps extends FieldTemplateProps {
  schema: CustomSchema;
}

export default function CustomFieldTemplate(props: CustomFieldTemplateProps) {
  const { classNames, required, description, children, schema, hidden } = props;
  const titleType =
    schema["x-styling"] && schema["x-styling"].titleType
      ? schema["x-styling"].titleType
      : "Title";
  const hasErrors = props.rawErrors && props.rawErrors.length > 0;
  if (hidden) return <div />;
  return (
    <FieldFrame className={classNames}>
      {description.props.description ? (
        <div style={{ display: "flex", flexDirection: "row" }}>
          {/* change title here */}
          {titleType === "Title" ? (
            <Title
              required={required}
              style={{
                marginTop: 20,
                marginRight: 20,
                marginBottom: 6,
                textAlign: "left",
                color: hasErrors ? "red" : undefined,
              }}
            >
              {description.props.description}
            </Title>
          ) : (
            <Title
              required={required}
              style={{
                marginTop: 20,
                marginRight: 20,
                marginBottom: 6,
                textAlign: "left",
                color: hasErrors ? "red" : undefined,
              }}
            >
              {description.props.description}
            </Title>
          )}
          <div style={{ marginTop: 20 }}>
            {schema.tooltip ? <Tooltip tooltip={schema.tooltip} /> : ""}
          </div>
        </div>
      ) : (
        ""
      )}
      {schema.helperText ? (
        <TextSmall
          style={{
            marginTop: 5,
            marginBottom: 10,
            marginRight: 20.5,
            textAlign: "left",
          }}
        >
          {schema.helperText}
        </TextSmall>
      ) : (
        <div style={{ height: 5 }} />
      )}
      {children}
    </FieldFrame>
  );
}

const FieldFrame = styled.div`
  border-top: 1px solid ${LIGHTGRAY};
`;
