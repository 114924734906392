import React, { useState } from "react";
import styled from "styled-components";
import { FieldProps } from "react-jsonschema-form";
import { JSONSchema6 } from "json-schema";
import { LIGHTGRAY, SNOW } from "../../../styles/colors";

interface SuggestionInputSchema extends JSONSchema6 {
  width: string;
  length: number;
  pattern?: any;
  hints?: Array<string>;
  locked?: boolean;
}

interface SuggestionInputProps extends FieldProps {
  schema: SuggestionInputSchema;
}

function isValidInputField(schema: SuggestionInputSchema, value: string) {
  const valueMatchesPattern = () => ((value.match(schema.pattern) || []).some(v => v === value))

  if (schema.length && schema.pattern) return value.length === schema.length && valueMatchesPattern();
  if (schema.length) return value.length === schema.length;

  if (schema.minLength && schema.pattern) return value.length >= schema.minLength && valueMatchesPattern();
  if (schema.minLength) return value.length >= schema.minLength;

  if (schema.pattern) return valueMatchesPattern();

  return value.length >= 1;
}

function handleInputChange(
  e: React.ChangeEvent<HTMLInputElement>,
  onChange: (val: string) => void,
  setValue: React.Dispatch<React.SetStateAction<string>>
) {
  setValue(e.target.value);
  onChange(e.target.value);
}

function renderHints(schema: SuggestionInputSchema, id: string) {
  if (schema.hints === undefined) return;
  return (
    <datalist id={`${id}-hints`}>
      {schema.hints.map((elem: string) => (
        <option key={elem} value={elem} />
      ))}
    </datalist>
  );
}

const SuggestionInput = (props: SuggestionInputProps) => {
  const [value, setValue] = useState("");
  const isValid = isValidInputField(props.schema, props.value || value);
  // Display the first example from JSON schema as a placeholder value
  const placeholder = props.schema.examples && props.schema.examples[0];
  const isDisabled = props.schema.locked || props.readonly;
  return (
    <SuggestionInputFrame className={isValid ? "valid" : "invalid"} isValid={isValid} width={props.schema.width}>
      <Input
        list={`${props.id}-hints`}
        name={`${props.id}-hints`}
        onChange={e => handleInputChange(e, props.onChange, setValue)}
        value={props.value}
        placeholder={typeof placeholder === "string" ? placeholder : ""}
        isValid={isValid}
        disabled={isDisabled}
      />
      {renderHints(props.schema, props.id || "")}
    </SuggestionInputFrame>
  );
};

type SuggestionInputFrameProps = {
  width?: string;
  isValid: boolean;
};

const Input = styled.input<SuggestionInputFrameProps>`
  width: ${props => props.width || "100%"};
  border: none;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
  outline: none;
  height: 100%;
  background-color: ${props => (props.isValid ? LIGHTGRAY : "#FFFFF")};

  @media (max-width: 700px) {
    font-size: 12px;
  }
`;

const SuggestionInputFrame = styled.div<SuggestionInputFrameProps>`
  width: ${props => props.width || "60%"};
  margin-right: 20px;
  margin-bottom: 20px;
  height: 40px;

  box-sizing: border-box;
  display: -ms-grid;
  -ms-grid-columns: 1fr 25px;
  display: grid;
  grid-template-columns: 1fr 25px;
  margin-top: 0px;
  overflow: hidden;

  align-items: center;
  cursor: pointer;

  position: relative;
  border: 1px solid #b8b8b8;

  .grid-elem:nth-child(2) {
    -ms-grid-column: 2;
  }
`;

export default SuggestionInput;
