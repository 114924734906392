import styled, { keyframes } from "styled-components";
import { LIGHTGRAY, PINK } from "../../../../styles/colors";

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  animation: ${rotate360} 1.5s linear infinite;
  transform: translateZ(0);
  border-top: 2px solid ${PINK};
  border-right: 2px solid transparent;
  border-bottom: 2px solid ${LIGHTGRAY};
  border-left: 2px solid transparent;
  background: transparent;
  width: 15px;
  height: 15px;
  border-radius: 50%;
`;

export default Spinner;