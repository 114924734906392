import React from "react";
import styled from "styled-components";

export default class ProgressActive extends React.Component {
  render() {
    return (
      <SvgFrame width="22" height="24" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12.5" cy="12.5" r="12.5" fill={"white"} />
        <line x1="0" y1="0" x2="12.5" y2="12.5" stroke="lightgray" strokeWidth="3" />
        <line x1="0" y1="12.5" x2="12.5" y2="0" stroke="lightgray" strokeWidth="3" />
      </SvgFrame>
    );
  }
}

const SvgFrame = styled.svg`
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;
