import React from "react";

export default class Check extends React.Component {
  render() {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 5.25C0 2.3505 2.3505 0 5.25 0H14.75C17.6495 0 20 2.3505 20 5.25V14.75C20 17.6495 17.6495 20 14.75 20H5.25C2.3505 20 0 17.6495 0 14.75V5.25Z" fill="#DA4978"/>
            <path d="M3.75 9.375L7.91667 13.75L16.25 5" stroke="white" stroke-width="2.625"/>
        </svg>

    );
  }
}
