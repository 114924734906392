import React from "react";

type SelectProps = {
  color?: string;
};

export default class Pen extends React.Component<SelectProps> {
  render() {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="12" fill="#ed3878" />
        <path
          d="M6 15.4997V18H8.50035L15.878 10.6223L13.3777 8.12196L6 15.4997ZM17.805 8.69537C18.065 8.43534 18.065 8.01195 17.805 7.75191L16.2481 6.19503C15.9881 5.93499 15.5647 5.93499 15.3046 6.19503L14.0845 7.4152L16.5848 9.91554L17.805 8.69537Z"
          fill="white"
        />
      </svg>
    );
  }
}
