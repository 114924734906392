import React, { Component } from "react";
import styled from "styled-components";
import { PINK } from "../styles/colors";
import IconCheck from "../icons/Check";

class Checkbox extends Component {
  render() {
    return (
      <CheckboxFrame onClick={this.props.onChange}>
        {this.props.checked ? (
          <div>
            <IconCheck />
          </div>
        ) : (
          <div />
        )}
      </CheckboxFrame>
    );
  }
}

const CheckboxFrame = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: none;
  border-radius: 6px;
  border: 3.58px solid ${PINK};
  padding: 0;
  margin: 0;
  height: 18px;
  min-width: 18px;
  max-width: 18px;
  margin-right: 10px;
`;

export default Checkbox;
