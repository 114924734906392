import React from "react";

export default class Business extends React.Component {
    render() {
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_2_1969)">
                    <path d="M7.5 19.5H4.5V12.5H5.5" stroke="#DA4978" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M9.5 14.5H11.5" stroke="#DA4978" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M9.5 16.5H11.5" stroke="#DA4978" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M9.5 12.5H11.5" stroke="#DA4978" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M12.5 7.5V5L19.5 7.5V19.5H7.5V9.5H13.5V19.333" stroke="#DA4978" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M15.5 16.5V8.5" stroke="#DA4978" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M17.5 16.5V9.5" stroke="#DA4978" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <defs>
                    <clipPath id="clip0_2_1969">
                        <rect width="16" height="16" fill="white" transform="translate(4 4)"/>
                    </clipPath>
                </defs>
            </svg>

        );
    }
}
