import React from "react";
import styled from "styled-components";
import { Text } from "../../../../styles/fonts";
import ProgressDone from "../../../../icons/ProgressDone";
import { AvailableSigner } from "./types";
import { getI18n } from "react-i18next";
import nb from "react-phone-number-input/locale/nb.json";
import en from "react-phone-number-input/locale/en.json";
import PhoneInput from "react-phone-number-input";
import { isPossibleNumber } from "libphonenumber-js";

export interface SignerFormProps {
  availableSigner: AvailableSigner;
  fromBisnode: boolean;
  onBlur: (formData: AvailableSigner) => void;
}

class SignerForm extends React.Component<SignerFormProps, AvailableSigner> {
  state = { ...this.props.availableSigner };

  render() {
    const { language } = getI18n();
    const translations = language.toLowerCase() === "nb" ? nb : en;

    const isDateValid =
      /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/.test(
        this.state.birthdate
      );
    const isNameValid = this.state.name.split(" ").length >= 2;

    return (
      <SignerFrame onBlur={() => this.props.onBlur({ ...this.state })}>
        {!this.props.fromBisnode
          ? [
              <TextFrame key={0}>
                <Text style={{ marginLeft: 6 }}>Name</Text>
              </TextFrame>,
              <InputFrame key={1}>
                <Input
                  value={this.state.name}
                  isValid={isNameValid}
                  onChange={(e) => this.onChange(e, "name")}
                />
                <div style={{ marginBottom: 10 }}>
                  {isNameValid ? (
                    <ProgressDone />
                  ) : (
                    <div style={{ width: 24 }} />
                  )}
                </div>
              </InputFrame>,
              <TextFrame key={2}>
                <Text placeholder="YYYY-MM-DD" style={{ marginLeft: 6 }}>
                  Birthdate (YYYY-MM-DD)
                </Text>
              </TextFrame>,
              <InputFrame key={3}>
                <Input
                  value={this.state.birthdate}
                  isValid={isDateValid}
                  onChange={(e) => this.onChange(e, "birthdate")}
                />
                <div style={{ marginRight: 20, marginBottom: 10 }}>
                  {isDateValid ? (
                    <ProgressDone />
                  ) : (
                    <div style={{ width: 24 }} />
                  )}
                </div>
              </InputFrame>,
            ]
          : ""}
        <TextFrame>
          <Text style={{ marginLeft: 6 }}>Email</Text>
        </TextFrame>
        <InputFrame>
          <Input
            value={this.state.email}
            isValid={this.state.emailIsValid}
            onChange={(e) => this.onChange(e, "email")}
          />
          <div style={{ marginBottom: 10 }}>
            {this.state.emailIsValid ? (
              <ProgressDone />
            ) : (
              <div style={{ width: 24 }} />
            )}
          </div>
        </InputFrame>
        <TextFrame>
          <Text style={{ marginLeft: 6 }}>Phone</Text>
        </TextFrame>
        <InputFrame style={{ justifyContent: "start" }}>
          <div style={{ width: "90%" }}>
            <StyledPhoneInput
              inputClassName={
                this.state.mobile.isPossiblePhonenumber ? "valid" : "invalid"
              }
              onChange={(e) => this.onPhoneChange(e || "", "phonenumber")}
              value={this.state.mobile.phonenumber}
              defaultCountry="NO"
              labels={translations}
            />
          </div>
          <div style={{ marginRight: 20, marginBottom: 10 }}>
            {this.state.mobile.isPossiblePhonenumber ? (
              <ProgressDone />
            ) : (
              <div style={{ width: 24 }} />
            )}
          </div>
        </InputFrame>
      </SignerFrame>
    );
  }

  onChange(
    e: React.ChangeEvent<HTMLInputElement>,
    key: "email" | "name" | "birthdate"
  ) {
    const state = this.state;
    state[key] = e.target.value;
    if (key === "email")
      state["emailIsValid"] =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          e.target.value
        );
    this.setState(state);
    this.props.onBlur(state);
  }

  onPhoneChange(s: string, key: "phonenumber") {
    const state = this.state;
    state.mobile[key] = s;
    state.mobile.isPossiblePhonenumber = isPossibleNumber(s);
    this.setState(state);
    this.props.onBlur(state);
  }
}

const StyledPhoneInput = styled(PhoneInput)`
  margin-left: 18px;
  width: 90%;

  > input[type="tel"] {
    padding-left: 10px;
    padding-right: 10px;
    outline: initial;
    height: 30px;
    border: 1px solid #cccccc;
    background-color: ${(props) =>
      props.inputClassName !== "valid" ? "#FFFFFF" : "transparent"};
    font-size: 16px;

    :focus {
      background-color: #ffffff;
      outline: auto blue;
    }
  }
  > * {
    margin-bottom: 10px;
  }
`;

const InputFrame = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

type InputProps = {
  isValid: boolean;
};

const Input = styled.input<InputProps>`
  flex-grow: 3;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  height: 30px;
  font-size: 16px;
  border: 1px solid #cccccc;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 10px;
  background-color: ${(props) => (!props.isValid ? "#FFFFFF" : "transparent")};

  &:focus {
    background-color: #ffffff;
  }
`;

const TextFrame = styled.div`
  width: 90%;
  margin-bottom: 5px;
`;

const SignerFrame = styled.div`
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #cccccc;
`;

export default SignerForm;
