import React from "react";
import styled from "styled-components";
import { RouteComponentProps } from "react-router-dom";

export default function LanguageSelector(props: RouteComponentProps<any>) {
    return (
        <Frame>
            <FlagLink href={props.location.pathname + "?lng=nb"}>
                <div style={{ height: 21, width: 42, marginTop: -4.5, marginLeft: -2 }}>
                    <No />
                </div>
            </FlagLink>
            <FlagLink href={props.location.pathname + "?lng=en"} style={{ marginLeft: 10 }}>
                <div style={{ height: 21, width: 42, marginLeft: -3.5 }}>
                    <En />
                </div>
            </FlagLink>
        </Frame>
    );
}

const FlagLink = styled.a`
    height: 21px;
    width: 35px;
    overflow: hidden;

    &:hover {
        opacity: 0.7;
        cursor: pointer;
    }
`;

const Frame = styled.div`
    display: flex;
    flex-direction: row;
`;

const En = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 30">
        <clipPath id="t">
            <path d="M30,15 h30 v15 z v15 h-30 z h-30 v-15 z v-15 h30 z" />
        </clipPath>
        <path d="M0,0 v30 h60 v-30 z" fill="#00247d" />
        <path d="M0,0 L60,30 M60,0 L0,30" stroke="#fff" strokeWidth="6" />
        <path d="M0,0 L60,30 M60,0 L0,30" clipPath="url(#t)" stroke="#cf142b" strokeWidth="4" />
        <path d="M30,0 v30 M0,15 h60" stroke="#fff" strokeWidth="10" />
        <path d="M30,0 v30 M0,15 h60" stroke="#cf142b" strokeWidth="6" />
    </svg>
);

const No = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1100 800">
        <rect width="1100" height="800" fill="#ef2b2d" />
        <rect width="200" height="800" x="300" fill="#fff" />
        <rect width="1100" height="200" y="300" fill="#fff" />
        <rect width="100" height="800" x="350" fill="#002868" />
        <rect width="1100" height="100" y="350" fill="#002868" />
    </svg>
);
