import { Reducer } from "redux";
import { ContentTitleState, ContentTitleActions } from "./types";

export const initialState = "";

const reducer: Reducer<ContentTitleState> = (state: ContentTitleState = initialState, action) => {
  switch ((action as ContentTitleActions).type) {
    case "@@content-title/UPDATE_CONTENT_TITLE":
      return action.payload;
    default:
      return state;
  }
};

export default reducer;
