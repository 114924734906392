import React from "react";
import { TextSmall } from "../../../styles/fonts";
import Remove from "../../../icons/Remove";
import Add from "../../../icons/Add";
import styled from "styled-components";
import { ArrayFieldTemplateProps } from "react-jsonschema-form";

export default function (props: ArrayFieldTemplateProps) {
  let { items, canAdd, onAddClick } = props;

  return (
    <ArrayFieldWrapper>
      {items &&
        items.map((element, index) => (
          <ArrayFieldFrame key={index}>
            <div
              style={{ marginTop: 30 }}
              onClick={element.onDropIndexClick(element.index)}
            >
              <Remove />
            </div>
            <div style={{ width: "100%" }}>{element.children}</div>
          </ArrayFieldFrame>
        ))}
      {canAdd && (
        <AddButtonFrame onClick={onAddClick} style={{ marginBottom: 10 }}>
          <Add />
          <TextSmall
            style={{ marginLeft: 10, marginTop: 10, marginBottom: 10 }}
          >
            Ny oppføring
          </TextSmall>
        </AddButtonFrame>
      )}
    </ArrayFieldWrapper>
  );
}

const AddButtonFrame = styled.button`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: transparent;
  border: none;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;

const ArrayFieldFrame = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const ArrayFieldWrapper = styled.div`
  margin-left: 30px;
  margin-top: 10px;
  .form-group {
    border-top: none;
    font-size: 12px;
    font-weight: 300; 
  }
`;
