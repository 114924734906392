import React from "react";

export default class Roles2 extends React.Component {
  render() {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_35_560)">
          <path
            d="M12 14.5C13.933 14.5 15.5 12.933 15.5 11C15.5 9.067 13.933 7.5 12 7.5C10.067 7.5 8.5 9.067 8.5 11C8.5 12.933 10.067 14.5 12 14.5Z"
            stroke="#DA4978"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10.5 14.162C10.4879 14.566 10.3498 14.9561 10.105 15.2777C9.86029 15.5994 9.52116 15.8365 9.13504 15.956L6.86804 17.044C6.77236 17.0842 6.68006 17.132 6.59204 17.187"
            stroke="#DA4978"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M17.408 17.188C17.3199 17.133 17.2276 17.0852 17.132 17.045L14.867 15.957C14.035 15.519 13.502 15.024 13.502 14.163"
            stroke="#DA4978"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 19.5C16.1421 19.5 19.5 16.1421 19.5 12C19.5 7.85786 16.1421 4.5 12 4.5C7.85786 4.5 4.5 7.85786 4.5 12C4.5 16.1421 7.85786 19.5 12 19.5Z"
            stroke="#DA4978"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_35_560">
            <rect
              width="16"
              height="16"
              fill="white"
              transform="translate(4 4)"
            />
          </clipPath>
        </defs>
      </svg>
    );
  }
}
