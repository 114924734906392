import React, { Component } from "react";
import styled from "styled-components";
import Pen from "../../../../icons/Pen";
import { connect } from "react-redux";
import Spinner from "./Spinner";
import { COAL } from "../../../../styles/colors";
import { getI18n } from "react-i18next";

type Props = {
  task: any;
};

class DetailedLoadingScreen extends Component<Props> {
  render() {
    return (
      <SignAgreementFrame>
        <Pen />
        <ProgressText key={this.props.task}>
          {getI18n().t(this.props.task)}
          <Spinner />
        </ProgressText>
      </SignAgreementFrame>
    );
  }
}

const ProgressText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 90%;
  justify-content: space-evenly;
  font-style: bold;
  margin-top: 40%;
  color: ${COAL}
`

const SignAgreementFrame = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;

  @media (max-width: 700px), (max-height: 700px) {
    width: 90%;
  }
`;


export default connect(
  undefined,
)(DetailedLoadingScreen);
