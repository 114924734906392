import React from "react";

export default class Roles extends React.Component {
  render() {
    return (
      <svg
        width="17"
        height="17"
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4 15H2V12H1V9C1 8.73478 1.10536 8.48043 1.29289 8.29289C1.48043 8.10536 1.73478 8 2 8H3M13 15H15V12H16V9C16 8.73478 15.8946 8.48043 15.7071 8.29289C15.5196 8.10536 15.2652 8 15 8H14M3.5 3C4.32843 3 5 3.67157 5 4.5C5 5.32843 4.32843 6 3.5 6C2.67157 6 2 5.32843 2 4.5C2 3.67157 2.67157 3 3.5 3ZM13.5 6C12.6716 6 12 5.32843 12 4.5C12 3.67157 12.6716 3 13.5 3C14.3284 3 15 3.67157 15 4.5C15 5.32843 14.3284 6 13.5 6ZM8.5 1C9.60457 1 10.5 1.89543 10.5 3C10.5 4.10457 9.60457 5 8.5 5C7.39543 5 6.5 4.10457 6.5 3C6.5 1.89543 7.39543 1 8.5 1ZM11 16H6V12H5V9C5 8.46957 5.21071 7.96086 5.58579 7.58579C5.96086 7.21071 6.46957 7 7 7H10C10.5304 7 11.0391 7.21071 11.4142 7.58579C11.7893 7.96086 12 8.46957 12 9V12H11V16Z"
          stroke="#DA4978"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
}
