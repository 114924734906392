import { Reducer } from "redux";
import { OnboardingStatusActions, OnboardingStatusState } from "./types";

export const initialState: OnboardingStatusState = {
  statusLines: [
    { name: "Register", state: "active" },
    { name: "Sign", state: "todo" },
    { name: "Done", state: "todo" }
  ]
};
/*
{ name: "Company", state: "active" },
    { name: "Review", state: "todo" },
    { name: "Sign", state: "todo" },
    { name: "Done", state: "todo" }
*/

function stepForward(state: OnboardingStatusState, index: number) {
  state.statusLines[index] = { ...state.statusLines[index], state: "done" };
  state.statusLines[index + 1] = { ...state.statusLines[index + 1], state: "active" };
}

function stepBackward(state: OnboardingStatusState, index: number) {
  state.statusLines[index] = { ...state.statusLines[index], state: "todo" };
  state.statusLines[index - 1] = { ...state.statusLines[index - 1], state: "active" };
}

const reducer: Reducer<OnboardingStatusState> = (state: OnboardingStatusState = initialState, action) => {
  const index = state.statusLines.findIndex(elem => elem.state === "active");

  switch ((action as OnboardingStatusActions).type) {
    case "@@onboarding-status/UPDATE_ONBOARDING_STATUS_FORWARD":
      stepForward(state, index);
      return { ...state, onboardingStatus: { statusLines: [...state.statusLines] } };
    case "@@onboarding-status/UPDATE_ONBOARDING_STATUS_BACKWARD":
      stepBackward(state, index);
      return { ...state, onboardingStatus: { statusLines: [...state.statusLines] } };
    case "@@onboarding-status/UPDATE_ONBOARDING_STATUS":
      return { ...state, statusLines: action.payload };
    default:
      return state;
  }
};

export default reducer;
