import { ActionCreator } from "redux";
import {
  UpdateOnboardingStatusBackwardAction,
  UpdateOnboardingStatusForwardAction,
  UpdateOnboardingStatusAction,
  Status
} from "./types";

export const updateOnboardingStatusForward: ActionCreator<UpdateOnboardingStatusForwardAction> = () => ({
  type: "@@onboarding-status/UPDATE_ONBOARDING_STATUS_FORWARD"
});

export const updateOnboardingStatusBackward: ActionCreator<UpdateOnboardingStatusBackwardAction> = () => ({
  type: "@@onboarding-status/UPDATE_ONBOARDING_STATUS_BACKWARD"
});

export const updateOnboardingStatus: ActionCreator<UpdateOnboardingStatusAction> = (statusLines: Status[]) => ({
  type: "@@onboarding-status/UPDATE_ONBOARDING_STATUS",
  payload: statusLines
});
